import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.can();
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | import('@angular/router').UrlTree | Observable<boolean | import('@angular/router').UrlTree> | Promise<boolean | import('@angular/router').UrlTree> {
        return this.can();
    }

    can() {
        if (this.authService.isLoggedIn()) {
            return true;
        }

        this.router.navigate(['extern/login']);
        return false;
    }
}