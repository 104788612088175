import { Component, OnDestroy } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})

export class PrivacyComponent {

  privacyText: SafeHtml;
  
  constructor(private apiService: ApiService, private languageService: LanguageService, private sanitizer: DomSanitizer) {

    this.apiService.post(RouteKeys.Privacy.get_value, { key: 'privacyDE' }).subscribe((response: any) => {
      this.privacyText = this.sanitizer.bypassSecurityTrustHtml(response.wert);
    }, err => {
      throw err?.error?.message || err?.error?.Message || 'Server-Fehler!';
    });

  }

}
