import { Component, Input, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { DokumenteEditComponent } from './dokumente-edit/dokumente-edit.component';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { formatDate } from 'devextreme/localization';
import { ConfigService } from '../../services/config.service';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-dokumente',
  templateUrl: './dokumente.component.html',
  styleUrls: ['./dokumente.component.scss'],
})
export class DokumenteComponent {
  @ViewChild('dokumenteEditPopup', { static: false }) dokumenteEditPopup: DokumenteEditComponent;
  @ViewChild('dokumenteDataGrid', { static: false }) dokumenteDataGrid: DxDataGridComponent;
  // @ViewChild('multiRichEditPopup', { static: false }) multiRichEditPopup: DocxMultiPreviewPopupComponent;

  @Input() type: string = null;
  @Input() readOnly: boolean = false;

  @Input() set id(id: number) {
    if (id !== null) {
      this.entityId = id;
      this.load(id);
    } else {
      this.dataSource = null;
    }
  }

  entityId: number;
  dataSource: any[] = [];
  selectedItem: any = null;
  filesToUpload: any[] = [];
  counter_crud: number = 0;
  old_dataSource: string;

  get hasFilesToUpload(): boolean {
    return this.filesToUpload !== undefined && this.filesToUpload !== null && this.filesToUpload.length > 0;
  }

  constructor(private apiService: ApiService, public configService: ConfigService, public popupService: PopupService) {
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.onClickNew = this.onClickNew.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.reload = this.reload.bind(this);
    this.onClickVorschau = this.onClickVorschau.bind(this);
    this.checkIsVorschauDisabled = this.checkIsVorschauDisabled.bind(this);
  }

  load(entityId: number) {
    this.apiService.post<any>(RouteKeys.Dokumente.getAll, { type: this.type, id: entityId }).subscribe(
      (response) => {
        this.dataSource = response;
        this.old_dataSource = JSON.stringify(this.dataSource);
        this.dokumenteDataGrid.instance.refresh();
      },
      (err) => {
        throw err.message;
      }
    );
  }

  reload() {
    this.load(this.entityId);
  }

  onSelectionChanged(e) {
    this.selectedItem = e.selectedRowsData[0];
  }

  onToolbarPreparing(e) {
    var searchPanel = e.toolbarOptions.items.filter((item) => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }

    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      cssClass: 'datagridToolbarNewBtn',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'add',
        text: 'Hinzufügen',
        onClick: this.onClickNew.bind(this),
      },
    });
  }

  onContentReady(e) {
    e.component.filter([['toDelete', '=', false], 'or', ['toDelete', '=', undefined], 'or', ['toDelete', '=', null]]);
    this.dokumenteDataGrid.instance.clearSelection();

    e.component.columnOption('command:select', {
      visibleIndex: 1,
      fixedPosition: 'right',
      visibleWidth: 70,
    });
  }

  isDisabled(e) {
    if (e.row.data.toAdd !== undefined && e.row.data.toAdd !== null && e.row.data.toAdd === true) {
      return true;
    }
    return false;
  }

  onClickNew(e) {
    this.dokumenteEditPopup.openNew();
  }

  onClickDelete(e) {
    let result = confirm('<i>Wollen Sie das Dokument wirklich löschen?</i>', 'Bestätigung');
    result.then((dialogResult) => {
      if (dialogResult) {
        if (e.row.data.toAdd !== undefined && e.row.data.toAdd !== null && e.row.data.toAdd === true) {
          this.filesToUpload = this.filesToUpload.filter((x) => x.id !== e.row.data.dokumentId);
          this.dataSource = this.dataSource.filter((x) => x.dokumentId !== e.row.data.dokumentId);
        } else {
          const exist = this.dataSource.find((x) => x.dokumentId === e.row.data.dokumentId);
          if (exist !== undefined && exist !== null) {
            exist.toDelete = true;
            this.dokumenteDataGrid.instance.refresh();
          }
        }
      }
    });
  }

  onClickDownload(e) {
    this.apiService.postDownload(
      RouteKeys.Dokumente.download,
      e.row.data.fileName,
      { type: this.getType(e.row.data.bereich), dokumentId: e.row.data.dokumentId, fileName: e.row.data.fileName },
      (err) => {
        if (err) {
          notify(
            {
              message: 'Die Datei wurde nicht gefunden!',
            },
            'error'
          );
        }
      }
    );
  }

  onAddClose(e) {
    if (this.dataSource === null) {
      this.dataSource = [];
    }
    const id_index = this.dataSource.length;

    for (let index = 0; index < e.length; index++) {
      const element = e[index];
      const newId = (id_index + index + 1) * -1; // z.B. Das vierte Dokument ist dann ID -4 . Damit die ID immer neu ist, damit es im Datagrid nicht beim Laden zu Fehlern kommt

      this.filesToUpload.push({
        id: newId,
        file: element,
      });

      this.dataSource.push({
        dokumentId: newId,
        bereich: this.type === 'Haushalt' ? 'Haushalt' : '',
        bezeichnung: element.name,
        fileName: element.name,
        datum: new Date(),
        istVomBuerger: false,
        toAdd: true,
      });
    }

    this.dokumenteDataGrid.instance.refresh();
    setTimeout(() => {
      if (this.dokumenteDataGrid.instance.totalCount() > 0) {
        this.dokumenteDataGrid.instance.editRow(this.dokumenteDataGrid.instance.totalCount() - 1);
      }
    }, 600);
  }

  onClickEdit(e) {
    e.component.editRow(e.row.rowIndex);
  }

  updateRow(e) {
    if (e.key.toAdd !== undefined && e.key.toAdd !== null && e.key.toAdd === true) {
      return;
    }
    e.newData.toEdit = true;
  }

  save(entityId: number, cb: () => void = null) {
    this.counter_crud = 0;

    if (this.dataSource == null || this.dataSource.length < 1) {
      if (cb !== undefined && cb !== null) {
        cb();
      }
    } else {
      const count = this.dataSource.filter(
        (ds) =>
          (ds.toDelete !== undefined && ds.toDelete !== null && ds.toDelete === true) ||
          (ds.toEdit !== undefined && ds.toEdit !== null && ds.toEdit === true) ||
          (ds.toAdd !== undefined && ds.toAdd !== null && ds.toAdd === true)
      ).length;
      this.counter_crud = count;

      if (count < 1) {
        if (cb !== undefined && cb !== null) {
          cb();
        }
      }

      for (let index = 0; index < this.dataSource.length; index++) {
        const ds = this.dataSource[index];
        if (ds.toDelete !== undefined && ds.toDelete !== null && ds.toDelete === true) {
          this.toDelete(entityId, ds.dokumentId, cb);
        } else if (ds.toEdit !== undefined && ds.toEdit !== null && ds.toEdit === true) {
          this.toEdit(entityId, ds.dokumentId, ds.bezeichnung, ds.dokumentenArtId, cb);
        } else if (ds.toAdd !== undefined && ds.toAdd !== null && ds.toAdd === true) {
          const fileToUpload = this.filesToUpload.find((x) => x.id === ds.dokumentId);
          this.toAdd(entityId, ds, fileToUpload.file, cb);
        }
      }
    }
  }

  toAdd(entityId: number, ds, file, cb: () => void = null) {
    const toSend = {
      entityId: entityId,
      type: this.type,
      bezeichnung: ds.bezeichnung,
      fileName: ds.fileName,
      dokumentenArtId: ds.dokumentenArtId,
    };

    let input = new FormData();
    input.append('file', file);
    input.append('vievmodel', JSON.stringify(toSend));

    this.apiService.upload(
      RouteKeys.Dokumente.save,
      input,
      (response) => {
        this.finish_crud(entityId, cb);
      },
      (err) => {
        throw err;
      }
    );
  }

  toEdit(entityId: number, dokumentId, bezeichnung, art, cb: () => void = null) {
    const toSend = { type: this.type, dokumentId: dokumentId, bezeichnung: bezeichnung, dokumentenArtId: art };

    this.apiService.post<any>(RouteKeys.Dokumente.update, toSend).subscribe(
      (response) => {
        this.finish_crud(entityId, cb);
      },
      (err) => {
        throw err;
      }
    );
  }

  toDelete(entityId: number, dokumentId, cb: () => void = null) {
    const toSend = { type: this.type, dokumentId: dokumentId };

    this.apiService.post(RouteKeys.Dokumente.remove, toSend).subscribe(
      (response) => {
        this.finish_crud(entityId, cb);
      },
      (err) => {
        throw err;
      }
    );
  }

  finish_crud(entityId: number, cb: () => void = null) {
    this.counter_crud--;
    if (this.counter_crud === 0) {
      this.load(entityId);
      if (cb !== undefined && cb !== null) {
        cb();
      }
    }
  }

  calculateDatum(e) {
    if (e.toAdd !== undefined && e.toAdd !== null && e.toAdd === true) {
      return 'Neu';
    } else {
      return formatDate(new Date(e.datum), 'dd.MM.yyyy');
    }
  }

  onRowDblClick(e) {
    if (e.data.bereich === 'Haushalt') {
      this.dokumenteDataGrid.instance.editRow(e.rowIndex);
    }
  }

  onClickVorschau(e) {
    const results: any[] = [];
    for (let index = 0; index < this.dataSource.length; index++) {
      const ds = this.dataSource[index];
      const exist = this.filesToUpload.find((x) => x.id === ds.dokumentId);
      if (exist === undefined) {
        results.push(ds);
      }
    }

    const index = results.findIndex((result) => {
      return result.dokumentId === e.row.data.dokumentId;
    });

    // this.multiRichEditPopup.openMulti(results, index);
    this.popupService.showDokumentEditPopup({ type: this.getType(e.row.data.bereich), doc: e.row.data, readOnly: true }, (c) => {});
  }

  onEditClose(e) {
    if (e.file !== undefined && e.file !== null) {
      const exist_filesToUpload = this.filesToUpload.find((x) => x.id === e.id);
      if (exist_filesToUpload !== undefined && exist_filesToUpload !== null) {
        exist_filesToUpload.file = e.file;
      } else {
        this.filesToUpload.push({
          id: e.id,
          file: e.file,
        });
      }

      const exist_dataSource = this.dataSource.find((x) => x.dokumentId === e.id);
      if (exist_dataSource !== undefined && exist_dataSource !== null) {
        exist_dataSource.bezeichnung = e.bezeichnung;
        exist_dataSource.fileName = e.file.name;
        if (exist_dataSource.toAdd !== true) {
          exist_dataSource.toEdit = true;
        }
      }
    } else {
      const exist_dataSource = this.dataSource.find((x) => x.dokumentId === e.id);
      if (exist_dataSource !== undefined && exist_dataSource !== null) {
        exist_dataSource.bezeichnung = e.bezeichnung;
        if (exist_dataSource.toAdd !== true) {
          exist_dataSource.toEdit = true;
        }
      }
    }

    this.dokumenteDataGrid.instance.refresh();
  }

  // disabledFileExtensions: string[] = ['msg', 'xlsx', 'xls'];
  enabledFileExtensions: string[] = ['pdf', 'doc', 'docx', 'txt', 'rtf', 'jpeg', 'jpg', 'bmp', 'png', 'gif'];

  checkIsVorschauDisabled(e: any) {
    if (e.row.data.toAdd !== undefined && e.row.data.toAdd !== null && e.row.data.toAdd === true) {
      return true;
    }

    const fileName = e.row.data.fileName;
    const fileExtensionArray = fileName.split('.');
    const fileExtension = fileExtensionArray[fileExtensionArray.length - 1];
    if (!this.enabledFileExtensions.includes(fileExtension)) {
      return true;
    }
    return false;
  }

  getIsChanged(): boolean {
    if (JSON.stringify(this.dataSource) !== this.old_dataSource) {
      return true;
    }
    return false;
  }

  isNotHaushalt(e) {
    return this.type === 'Haushalt' && e.row.data.bereich !== 'Haushalt';
  }

  private getType(bereich: string) {
    if (this.type !== 'Haushalt') {
      return this.type;
    } else {
      if (bereich === 'Haushalt') {
        return 'Haushalt';
      } else if (bereich.startsWith('Vertrag')) {
        return 'Vertrag';
      } else if (bereich.startsWith('Antrag')) {
        return 'Antrag';
      } else {
        return '';
      }
    }
  }
}
