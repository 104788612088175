import { Component, OnDestroy } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent {

  imprintText: SafeHtml;

  constructor(private apiService: ApiService, private languageService: LanguageService, private sanitizer: DomSanitizer) {

      this.apiService.post(RouteKeys.Imprint.get_value, { key: 'impressumDE' }).subscribe((response: any) => {
        this.imprintText = this.sanitizer.bypassSecurityTrustHtml(response.wert);
      }, err => {
        throw err?.error?.message || err?.error?.Message || 'Server-Fehler!';
      });
  }

}
