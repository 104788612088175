<dx-popup #popup [wrapperAttr]="{ class: 'ek-rechner-popup' }" [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="true" [title]="titel" width="1500" height="auto" maxHeight="900">

    <div *dxTemplate="let data of 'content'">
      <dx-scroll-view #scrollView height="100%">

        <dx-form #form [width]="'100%'" [(formData)]="formData" labelLocation="top" [colCount]="1">
          <dxi-item itemType="group" [colCount]="anzahlSpalten">
            <dxi-item dataField="ekBerechnung.datumBerechnung" editorType="dxDateBox" [label]="{text: 'Berechnet am'}" [colSpan]="2">
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>

            <dxi-item dataField="ekBerechnung.rechtsgrundlage" [label]="{text: 'Rechtsgrundlage'}" [colSpan]="2">
              <div *dxTemplate>
                <dx-select-box [(value)]="formData.ekBerechnung.rechtsgrundlage" [showClearButton]="false"
                  [dataSource]="dsRechtsgrundlagen" valueExpr="id" displayExpr="bezeichnung"
                  [disabled]="formData.ekBerechnung.ekDetails!==null && formData.ekBerechnung.ekDetails!==undefined && formData.ekBerechnung.ekDetails.length > 0">
                </dx-select-box>                            
              </div>
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="ekBerechnung.datumNachweis" editorType="dxDateBox" [label]="{text: 'EK-Nachweis vom'}" [colSpan]="2">
            </dxi-item>

            <dxi-item [dataField]="'ekBerechnung.ekPersonId_1'" [label]="{text: 'Person 1'}" [colSpan]="2">
              <div *dxTemplate>
                <dx-select-box [(value)]="formData.ekBerechnung.ekPersonId_1" [showClearButton]="false"
                  [dataSource]="formData.hhaPersonen" valueExpr="hhaAngehoerigerId" displayExpr="name" (onSelectionChanged)="onChangePerson($event, 1)">
                </dx-select-box> 
              </div>
              <dxi-validation-rule type="required"></dxi-validation-rule>
            </dxi-item>
            <dxi-item [dataField]="'ekBerechnung.ekPersonId_2'" [label]="{text: 'Person 2'}" [colSpan]="2">
              <div *dxTemplate>
                <dx-select-box [(value)]="formData.ekBerechnung.ekPersonId_2" [showClearButton]="true"
                  [dataSource]="formData.hhaPersonen" valueExpr="hhaAngehoerigerId" displayExpr="name" (onSelectionChanged)="onChangePerson($event, 2)" 
                  (onValueChanged)="onChangeValuePerson($event, 2)">
                </dx-select-box> 
              </div>
            </dxi-item>
            <dxi-item *ngIf="ekPerson_3 !== null" [dataField]="'ekBerechnung.ekPersonId_3'" [label]="{text: 'Person 3'}" [colSpan]="2">
              <div *dxTemplate>
                <dx-select-box [(value)]="formData.ekBerechnung.ekPersonId_3" [showClearButton]="true"
                  [dataSource]="formData.hhaPersonen" valueExpr="hhaAngehoerigerId" displayExpr="name" (onSelectionChanged)="onChangePerson($event, 3)" 
                  (onValueChanged)="onChangeValuePerson($event, 3)">
                </dx-select-box> 
              </div>
            </dxi-item>

            <dxi-item cssClass="centered-item buttons-item" [colSpan]="1" [label]="{text: '&nbsp;'}" [visible]="formData.hhaPersonen.length > 2">
              <div *dxTemplate>
                  <dx-button type="default" [icon]="ekPerson_3===null ? 'add' : 'trash'" stylingMode="contained" 
                      [hint]="ekPerson_3===null ? 'Weitere Person hinzufügen' : 'Letzte Person löschen'" (onClick)="onClickNeuePerson($event)">
                  </dx-button>
              </div>
            </dxi-item>                  
          </dxi-item>
          <dxi-item itemType="group" [colCount]="anzahlSpalten">
            <dxi-item dataField="ekBerechnung.bemerkung" editorType="dxTextBox" [label]="{text: 'Bemerkung'}" [colSpan]="6">
            </dxi-item>
          </dxi-item>

            <!-- Einkünfte -->
          <dxi-item itemType="group" [caption]="'Einkünfte'">
            <dxi-item *ngIf="ekEinnahmen.length > 0" itemType="group" [colCount]="anzahlSpalten">
              <dxi-item itemType="empty" [colSpan]="4"></dxi-item>
              <dxi-item [colSpan]="2">
                <span><b>Frequenz</b></span>
              </dxi-item>
              <dxi-item [colSpan]="2">
                <b><span [textContent]="ekPerson_1 !== null && ekPerson_1 !== undefined ? ekPerson_1.name : ''"></span></b>
              </dxi-item>                  
              <dxi-item [colSpan]="2">
                <b><span [textContent]="ekPerson_2 !== null && ekPerson_2 !== undefined ? ekPerson_2.name : ''"></span></b>
              </dxi-item>                    
              <dxi-item *ngIf="ekPerson_3!==null" [colSpan]="2">
                <b><span [textContent]="ekPerson_3!==undefined ? ekPerson_3.name : ''"></span></b>
              </dxi-item>
            </dxi-item>
            <dxi-item itemType="group" *ngFor="let ekPosition of ekEinnahmen; let i = index">
              <dxi-item *ngIf="ekPosition.einkommenstyp === 'einnahme'" itemType="group" [colCount]="anzahlSpalten">
                
                <dxi-item [label]="{ visible: false }" [colSpan]="4">
                  <dxi-validation-rule type="required" message="Einkommensart wird benötigt"></dxi-validation-rule>
                  <div *dxTemplate>
                    <dx-select-box [(value)]="ekPosition.einkommensartId"
                        [dataSource]="dsEinnahmen" valueExpr="id" displayExpr="bezeichnung"
                        (onSelectionChanged)="onChangeEinkommensArt($event)">
                    </dx-select-box>
                  </div>
                </dxi-item>

                <dxi-item [label]="{ visible: false }" [colSpan]="2">
                  <div *dxTemplate>
                    <dx-select-box [(value)]="ekPosition.frequenz" [showClearButton]="false"
                        [dataSource]="dsEkPerioden" valueExpr="id" displayExpr="name" (onSelectionChanged)="onChangeEinkommen($event)">
                    </dx-select-box>
                  </div>
                </dxi-item>

                <dxi-item [label]="{ visible: false }" [dataField]="ekPosition.betrag_1" [colSpan]="2">
                  <div *dxTemplate>
                    <dx-number-box [(value)]="ekPosition.betrag_1" [max]="10000000" [min]="0" [showSpinButtons]="false" format="#,##0.00 €" 
                      (onChange)="onChangeEinkommen($event)" [disabled]="formData.ekBerechnung.ekPersonId_1 === null || formData.ekBerechnung.ekPersonId_1 === undefined">
                    </dx-number-box>
                  </div>
                </dxi-item>

                <dxi-item [label]="{ visible: false }" [dataField]="ekPosition.betrag_2" [colSpan]="2">
                  <div *dxTemplate>
                    <dx-number-box [(value)]="ekPosition.betrag_2" [max]="10000000" [min]="0" [showSpinButtons]="false" format="#,##0.00 €" 
                        (onChange)="onChangeEinkommen($event)" [disabled]="formData.ekBerechnung.ekPersonId_2 === null || formData.ekBerechnung.ekPersonId_2 === undefined">
                    </dx-number-box>
                  </div>
                </dxi-item>

                <dxi-item *ngIf="ekPerson_3!==null" [label]="{ visible: false }" [dataField]="ekPosition.betrag_3" [colSpan]="2">
                  <div *dxTemplate>
                    <dx-number-box [(value)]="ekPosition.betrag_3" [max]="10000000" [min]="0" [showSpinButtons]="false" format="#,##0.00 €" 
                        (onChange)="onChangeEinkommen($event)" [disabled]="formData.ekBerechnung.ekPersonId_3 === null || formData.ekBerechnung.ekPersonId_3 === undefined">
                    </dx-number-box>
                  </div>
                </dxi-item>                          

                <dxi-item cssClass="centered-item buttons-item" [colSpan]="1">
                  <div *dxTemplate>
                    <dx-button type="default" icon="tableproperties" stylingMode="contained" [disabled]="true"
                        (onClick)="onClickHifsrechner($event, ekPosition)">
                    </dx-button>
                    <dx-button type="default" icon="trash" stylingMode="contained"
                        (onClick)="onClickRemoveEinkommenDetails($event, ekPosition)">
                    </dx-button>
                  </div>
                </dxi-item>                                            
              </dxi-item>
            </dxi-item>

            <dxi-item itemType="group" [colCount]="anzahlSpalten">
              <dxi-item cssClass="centered-item" [label]="{ visible: false }" [colSpan]="6">
                <span class="text-bold">Einnahmen gesamt:</span>
              </dxi-item>      
              <dxi-item cssClass="right-item" [label]="{ visible: false }" [colSpan]="2">
                <span class="text-bold sub-ergebnis">{{gesamtEinnahmen1 | currency:'EUR'}} / Jahr</span>
              </dxi-item>                                          
              <dxi-item cssClass="right-item" [label]="{ visible: false }" [colSpan]="2">
                <span class="text-bold sub-ergebnis">{{gesamtEinnahmen2 | currency:'EUR'}} / Jahr</span>
              </dxi-item>            
              <dxi-item *ngIf="ekPerson_3!==null" cssClass="right-item" [label]="{ visible: false }" [colSpan]="2">
                <span class="text-bold sub-ergebnis">{{gesamtEinnahmen3 | currency:'EUR'}} / Jahr</span>
              </dxi-item>
              <dxi-item itemType="empty"></dxi-item>            
              <dxi-item [cssClass]='ekEinnahmen.length>0 ? "centered-item" : ""' [colSpan]="3">
                <div *dxTemplate>
                  <dx-button type="default" icon="add" stylingMode="contained" text="Einnahmeposition hinzufügen" 
                    (onClick)="onClickAddEinkommenDetails($event, 'einnahme')" [disabled]="formData.ekBerechnung.rechtsgrundlage===null || 
                    formData.ekBerechnung.rechtsgrundlage===undefined || ekPerson_1===null || ekPerson_1=== undefined">
                  </dx-button>
                </div>
              </dxi-item>
            </dxi-item>
          </dxi-item>                                    

            <!-- Abzüge -->
          <dxi-item itemType="group" [caption]="'Abzüge'">
            <dxi-item *ngIf="ekAbzuege.length > 0" itemType="group" [colCount]="anzahlSpalten">
              <dxi-item itemType="empty" [colSpan]="4"></dxi-item>
              <dxi-item [colSpan]="2">
                <span><b>Frequenz</b></span>
              </dxi-item>
              <dxi-item [colSpan]="2">
                <b><span [textContent]="ekPerson_1 !== null && ekPerson_1 !== undefined ? ekPerson_1.name : ''"></span></b>
              </dxi-item>                  
              <dxi-item [colSpan]="2">
                <b><span [textContent]="ekPerson_2 !== null && ekPerson_2 !== undefined ? ekPerson_2.name : ''"></span></b>
              </dxi-item>                    
              <dxi-item *ngIf="ekPerson_3!==null" [colSpan]="2">
                <b><span [textContent]="ekPerson_3!==undefined ? ekPerson_3.name : ''"></span></b>
              </dxi-item>
            </dxi-item>                                
              <dxi-item itemType="group" *ngFor="let ekPosition of ekAbzuege; let i = index">
                <dxi-item *ngIf="ekPosition.einkommenstyp === 'abzug'" itemType="group" [colCount]="anzahlSpalten">
                  <dxi-item [label]="{ visible: false }" [colSpan]="4">
                    <dxi-validation-rule type="required" message="Einkommensart wird benötigt"></dxi-validation-rule>                                                
                    <div *dxTemplate>
                      <dx-select-box [(value)]="ekPosition.einkommensartId"
                          [dataSource]="dsAbzuege" valueExpr="id" displayExpr="bezeichnung" 
                          (onSelectionChanged)="onChangeEinkommensArt($event)">
                      </dx-select-box>
                    </div>
                  </dxi-item>

                  <dxi-item [label]="{ visible: false }" [colSpan]="2">
                    <div *dxTemplate>
                      <dx-select-box [(value)]="ekPosition.frequenz" [showClearButton]="false"
                          [dataSource]="dsEkPerioden" valueExpr="id" displayExpr="name" (onSelectionChanged)="onChangeEinkommen($event)">
                      </dx-select-box>
                    </div>
                  </dxi-item>

                  <dxi-item [label]="{ visible: false }" [dataField]="ekPosition.betrag_1" [colSpan]="2">
                    <div *dxTemplate>
                      <dx-number-box [(value)]="ekPosition.betrag_1" [max]="10000000" [min]="0" [showSpinButtons]="false" format="#,##0.00 €" 
                          (onChange)="onChangeEinkommen($event)" [disabled]="formData.ekBerechnung.ekPersonId_1 === null || formData.ekBerechnung.ekPersonId_1 === undefined">
                      </dx-number-box>
                    </div>
                  </dxi-item>
                  
                  <dxi-item [label]="{ visible: false }" [dataField]="ekPosition.betrag_2" [colSpan]="2">
                    <div *dxTemplate>
                      <dx-number-box [(value)]="ekPosition.betrag_2" [max]="10000000" [min]="0" [showSpinButtons]="false" format="#,##0.00 €" 
                          (onChange)="onChangeEinkommen($event)" [disabled]="formData.ekBerechnung.ekPersonId_2 === null || formData.ekBerechnung.ekPersonId_2 === undefined">
                      </dx-number-box>
                    </div>
                  </dxi-item>                            

                  <dxi-item *ngIf="ekPerson_3!==null" [label]="{ visible: false }" [dataField]="ekPosition.betrag_3" [colSpan]="2">
                    <div *dxTemplate>
                      <dx-number-box [(value)]="ekPosition.betrag_3" [max]="10000000" [min]="0" [showSpinButtons]="false" format="#,##0.00 €" 
                          (onChange)="onChangeEinkommen($event)" [disabled]="formData.ekBerechnung.ekPersonId_3 === null || formData.ekBerechnung.ekPersonId_3 === undefined">
                      </dx-number-box>
                    </div>
                  </dxi-item>

                  <dxi-item cssClass="centered-item buttons-item" [colSpan]="1">
                    <div *dxTemplate>
                      <dx-button type="default" icon="tableproperties" stylingMode="contained" [disabled]="true"
                          (onClick)="onClickHifsrechner($event, ekPosition)">
                      </dx-button>                                        
                      <dx-button type="default" icon="trash" stylingMode="contained"
                        (onClick)="onClickRemoveEinkommenDetails($event, ekPosition)">
                      </dx-button>
                    </div>
                  </dxi-item>                                                                                        
                </dxi-item>
              </dxi-item>

              <dxi-item itemType="group" [colCount]="anzahlSpalten">
                <dxi-item cssClass="centered-item" [label]="{ visible: false }" [colSpan]="6">
                  <span class="text-bold">Abzüge gesamt:</span>
                </dxi-item>      
                <dxi-item cssClass="right-item" [label]="{ visible: false }" [colSpan]="2">
                  <span class="text-bold sub-ergebnis">{{gesamtAbzuege1 | currency:'EUR'}} / Jahr</span>
                </dxi-item>                                                                  
                <dxi-item cssClass="right-item" [label]="{ visible: false }" [colSpan]="2">
                  <span class="text-bold sub-ergebnis">{{gesamtAbzuege2 | currency:'EUR'}} / Jahr</span>
                </dxi-item>                        
                <dxi-item *ngIf="ekPerson_3!==null" cssClass="right-item" [label]="{ visible: false }" [colSpan]="2">
                  <span class="text-bold sub-ergebnis">{{gesamtAbzuege3 | currency:'EUR'}} / Jahr</span>
                </dxi-item>
                <dxi-item itemType="empty"></dxi-item>
                <dxi-item [cssClass]='ekAbzuege.length>0 ? "centered-item" : ""' [colSpan]="3">
                  <div *dxTemplate>
                    <dx-button type="default" icon="add" stylingMode="contained" 
                      text="Abzugsposition hinzufügen" [disabled]="formData.ekBerechnung.rechtsgrundlage===null || 
                      formData.ekBerechnung.rechtsgrundlage===undefined || ekPerson_1===null || ekPerson_1=== undefined"
                      (onClick)="onClickAddEinkommenDetails($event, 'abzug')">
                    </dx-button>
                  </div>
                </dxi-item>                               
              </dxi-item>                                                                             
          </dxi-item>    
        </dx-form>
      </dx-scroll-view>
      <div class="ergebnis-bereich text-bold">
        <span class="ergebnis-bereich-label">Maßgebliches Einkommen pro Monat:</span>
        <span class="ergebnis-bereich-wert">{{betragMonat | currency : 'EUR'}}</span>
        <span class="ergebnis-bereich-label">Maßgebliches Einkommen pro Jahr:</span>
        <span>{{betragJahr | currency : 'EUR'}}</span>
      </div>
    </div>  
    
    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Übernehmen', type: 'default', stylingMode: 'contained',  icon: 'save', disabled: (isLoading || 
          (ekAbzuege.length===0 && ekEinnahmen.length===0)), onClick: onClickSave }">
    </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-toolbar-item>    
</dx-popup>