import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteKeys } from '../helper/route-keys.helper';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { PollService } from './poll.service';
import { StorageService } from './storage.service';

@Injectable()
export class AuthService {

  constructor(private router: Router, private apiService: ApiService, private storageService: StorageService, private configService: ConfigService, private pollService: PollService) { }

  login(benutzername: string, passwort: string, verifyCode: string, okCb: () => void, errCb: (err: any) => void) {
    this.apiService.post<any>(RouteKeys.Auth.get_token, { username: benutzername, password: passwort, verifyCode: verifyCode }).subscribe(response => {
      this.storageService.saveToSession('benutzerId', response.id);
      this.storageService.saveToSession('benutzername', response.benutzername);
      this.storageService.saveToSession('token', response.token);
      this.storageService.saveToSession('rollen', response.rollen);
      this.storageService.saveToSession('nachname', response.nachname);
      this.storageService.saveToSession('vorname', response.vorname);
      okCb();
    }, err => {
      errCb(err)
    });
  }

  logout() {
    this.pollService.stop();
    this.router.navigate(['extern']);
    this.storageService.clearSession();
  }

  isLoggedIn(): boolean {
    const token = this.storageService.getFromSession<string>('token');
    const benutzername = this.storageService.getFromSession<string>('benutzername');
    if ((token && token.length) && (benutzername && benutzername.length)) {
      return true;
    }

    return false;
  }

  getName(): string {
    return this.storageService.getFromSession<string>('vorname') + ' ' + this.storageService.getFromSession<string>('nachname');
  }

  setName(vorname: string, nachname: string) {
    this.storageService.saveToSession('vorname', vorname);
    this.storageService.saveToSession('nachname', nachname);
  }

  getBenutzerName(): string {
    return this.storageService.getFromSession<string>('benutzername');
  }

  getBenutzerId(): string {
    return this.storageService.getFromSession<string>('benutzerId');
  }

  isInRole(role: string = null): boolean {
    const _roles = this.getRoles();
    let result = false;
    _roles.forEach(_role => {
      const exist = this.configService.rollen.find(x => x.id === _role);
      if (exist !== undefined && exist !== null) {
        if (exist.bezeichnung.toLocaleLowerCase() === role.toLocaleLowerCase()) {
          result = true;
        }
      }
    });

    return result;
  }

  isInOneOfThisRoles(roles: string[]): boolean {
    let result = false;
    roles.forEach(r => {
      if (this.isInRole(r)) {
        result = true;
      }
    });

    return result;
  }

  getRoles(): number[] {
    return this.storageService.getFromSession<number[]>('rollen');
  }

  setVerifyCode(benutzername: string, passwort: string, okCb: () => void, errCb: (err: any) => void) {
    this.apiService.post<any>(RouteKeys.Auth.send_verify_code, { username: benutzername, password: passwort }).subscribe(response => {
      if(response === true)
      {
        okCb();
      }
    }, err => {
      errCb(err)
    });
  }

  loginNo2FA(benutzername: string, passwort: string, okCb: () => void, errCb: (err: any) => void) {
    this.apiService.post<any>(RouteKeys.Auth.login_no_2FA, { username: benutzername, password: passwort}).subscribe(response => {
      this.storageService.saveToSession('benutzerId', response.id);
      this.storageService.saveToSession('benutzername', response.benutzername);
      this.storageService.saveToSession('token', response.token);
      this.storageService.saveToSession('rollen', response.rollen);
      this.storageService.saveToSession('nachname', response.nachname);
      this.storageService.saveToSession('vorname', response.vorname);
      okCb();
    }, err => {
      errCb(err)
    });
  }

  isVerwaltung2FAOn(okCb: (result: number) => void, errCb: (err: any) => void){
    this.apiService.post<any>(RouteKeys.Auth.get_is_2FA_on).subscribe(result => {
      okCb(result);
    }, err => {
      errCb(err);
    });
  }
}
