import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ApiService } from '../services/api.service';
import { RouteKeys } from './route-keys.helper';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private zone: NgZone, private apiService: ApiService) { }

    handleError(error: any) {
          
        console.error('1', error); // wichtig um in der Console Fehler zu sehen

        this.zone.run(() => {
            let _error: any = {
                message: error.message,
                stack: error.stack,
                status: error.status || null,
                browser: this.detectBrowserName() + " " + this.detectBrowserVersion(),
                url: location.href
            };

            this.apiService.post(RouteKeys.Exception.log_error, _error).subscribe();
        });
    }

    detectBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
          case agent.indexOf('edge') > -1:
            return 'edge';
          case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other';
        }
      }
    
      detectBrowserVersion() {
        const userAgent = navigator.userAgent;
        let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        let tem;
        if (/trident/i.test(matchTest[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
          return 'IE ' + (tem[1] || '');
        }
        if (matchTest[1] === 'Chrome') {
          tem = userAgent.match(/\b(OPR|Edg)\/(\d+)/);
          if (tem != null) {
            return tem.slice(1).join(' ').replace('OPR', 'Opera');
          }
        }
        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
          matchTest.splice(1, 1, tem[1]);
        }
        return matchTest.join(' ');
      }
}