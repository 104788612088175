<dx-data-grid
  class="ekrechner-datagrid"
  #ekrechnerDataGrid
  [dataSource]="dataSource"
  height="auto"
  [columnAutoWidth]="true"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onRowDblClick)="onClickEdit($event)"
>
  <dxo-selection mode="single"></dxo-selection>
  <dxo-search-panel [width]="300" [visible]="true"> </dxo-search-panel>

  <!-- <dxi-column dataField="typ" caption="Typ"></dxi-column> -->
  <dxi-column dataField="datumBerechnung" dataType="date" caption="Datum" format="dd.MM.yyyy" [width]="110"> </dxi-column>
  <dxi-column dataField="betragJahr" caption="Maßgebliches Einkommen/Jahr" format="#,##0.00 €"></dxi-column>
  <dxi-column dataField="betragMonat" caption="Maßgebliches Einkommen/Monat" format="#,##0.00 €"></dxi-column>
  <dxi-column dataField="bemerkung" caption="Bemerkung"></dxi-column>
  <dxi-column dataField="rechtsgrundlage" caption="Rechtsgrundlage">
    <dxo-lookup [dataSource]="dsRechtsgrundlagen" displayExpr="bezeichnung" valueExpr="id"> </dxo-lookup>
  </dxi-column>

  <dxi-column
    type="buttons"
    [width]="120"
    [allowReordering]="false"
    [allowResizing]="false"
    [allowHiding]="false"
    [fixed]="true"
    [showInColumnChooser]="false"
  >
    <dxi-button hint="Bearbeiten" icon="edit" [onClick]="onClickEdit"></dxi-button>
    <dxi-button hint="Löschen" icon="trash" [onClick]="onClickDelete"></dxi-button>
  </dxi-column>
</dx-data-grid>

<app-einkommensrechner #ekRechner *ngIf="isEkRechnerOpen" (onClose)="onEkRechnerClose($event)"></app-einkommensrechner>