import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss']
})
export class TableContainerComponent {
  
  @Input() setHeader1: boolean = false;
  @Input() setHeader2: boolean = true;

  @Input() setFooter: boolean = false;

  constructor() { }

}
