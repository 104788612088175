import { Injectable } from '@angular/core';
import { CanActivate, Router, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot, } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RollenGuard implements CanActivate, CanLoad {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return this.can(route.data.neededRoles);
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.can(route.data.neededRoles);
    }

    can(neededRoles: string[]) {
        if (!this.authService.isLoggedIn()) {
            return false;
        }
        let isInRole = false;
        isInRole = this.authService.isInOneOfThisRoles(neededRoles);
        return isInRole;
    }
}


