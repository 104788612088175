<dx-data-grid class="bilder-datagrid" #bilderDataGrid [dataSource]="dataSource" height="100%"
  [columnAutoWidth]="true" (onSelectionChanged)="onSelectionChanged($event)" (onRowPrepared)="onRowPrepared($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)" (onContentReady)="onContentReady($event)"
  (onRowUpdated)="onRowUpdated($event)">

  <dxo-editing mode="form" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false">
    <dxo-form>
      <dxi-item dataField="bezeichnung" [label]="{ text: 'Bezeichnung' }"></dxi-item>
    </dxo-form>
  </dxo-editing>

  <dxo-sorting mode="none"></dxo-sorting>
  <dxo-row-dragging [allowReordering]="true" [onReorder]="onReorder" [showDragIcons]="true"></dxo-row-dragging>

  <dxo-selection selectAllMode="page" mode="single"></dxo-selection>

  <dxo-paging [pageSize]="30"></dxo-paging>
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-header-filter [visible]="false"></dxo-header-filter>
  <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>

  <dxo-search-panel [width]="300" [visible]="true">
  </dxo-search-panel>

  <dxi-column dataField="preview" [width]="100" cellTemplate="previewCellTemplate" caption="Vorschau"></dxi-column>
  <dxi-column dataField="bezeichnung" caption="Bezeichnung"></dxi-column>
  <dxi-column dataField="fileName" caption="Dateiname"></dxi-column>

  <dxi-column type="buttons" [width]="100" [allowReordering]="false" [allowResizing]="false" [allowHiding]="false"
    [fixed]="true" [showInColumnChooser]="false">
    <dxi-button hint="Bearbeiten" icon="edit" [onClick]="onClickEdit" *ngIf="!readOnly"></dxi-button>
    <dxi-button hint="Löschen" icon="trash" [onClick]="onClickDelete" *ngIf="!readOnly"></dxi-button>
  </dxi-column>

  <div *dxTemplate="let data of 'previewCellTemplate'">
    <img [attr.src]="data.data.preview | secure | async" [alt]="data.data.bezeichnung"/>
  </div>

</dx-data-grid>

<bilder-edit (onClose)="onEditClose($event)" #bilderEditPopup></bilder-edit>