import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

import 'devextreme/localization/globalize/number';
import 'devextreme/localization/globalize/date';
import 'devextreme/localization/globalize/currency';
import 'devextreme/localization/globalize/message';

import deMessages from 'devextreme/localization/messages/de.json';
import enMessages from 'devextreme/localization/messages/en.json';

import supplemental from 'devextreme-cldr-data/supplemental.json';
import deCldrData from 'devextreme-cldr-data/de.json';
import enCldrData from 'devextreme-cldr-data/en.json';

import Globalize from 'globalize';
import { locale } from 'devextreme/localization';
import { Router } from '@angular/router';

@Injectable()
export class LanguageService {

  private _blackListWarnings: string[] = [];
  private _selectedLanguage: BehaviorSubject<string> = new BehaviorSubject(navigator.language);

  public get get_selectedLanguageObservable(): Observable<string> {
    return this._selectedLanguage.asObservable();
  }

  constructor(private storageService: StorageService, private router: Router) {
    Globalize.load(
      supplemental, deCldrData, enCldrData
    );

    this.loadMessages(deMessages);
    this.loadMessages(enMessages);
    this.loadMessages(window.defaultLang.getDictionary());
  }

  public init() {
    this.get_selectedLanguageObservable.subscribe(lang => {
      Globalize.locale(lang);
      locale(lang);
    });

    const _lang = this.storageService.getFromLocale<string>('lang');
    if (_lang !== null && _lang !== undefined && _lang.length > 0) {
      this._selectedLanguage.next(_lang);
    }
    else {
      let _default_lang = navigator.language;
      if (_default_lang === 'de-DE') {
        _default_lang = 'de';
      }
      this._selectedLanguage.next(_default_lang);
    }
  }

  private loadMessages(messages: any) {
    if (messages !== null && messages !== undefined) {
      Globalize.loadMessages(messages);
    }
  }

  public setLanguage(lang: string) {
    if (lang !== null && lang !== undefined && lang.length > 0 && this._selectedLanguage.value !== lang) {
      this._selectedLanguage.next(lang);
      this.storageService.saveToLocale('lang', lang);
      this.forcePageRepaint();
    }
  }

  private forcePageRepaint() {
    // location.reload();
  }

  //---------------------

  public translate(key: string, params: string[] = []): string {
    return this.formatMessage(key, params) || '#' + key + '#';
  }

  private formatMessage(key: string, params: string[] = []): string {
    try {
      return Globalize.formatMessage(key, params);
    }
    catch (ex) {

      const msg = ex.message;
      
      if (!this._blackListWarnings.find(x=> x === msg)) {
        this._blackListWarnings.push(msg);
        console.log(msg);
      }
      
      return null;
    }
  }
}
