import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { ApiService } from 'src/app/share/services/api.service';
import { ConfigService } from 'src/app/share/services/config.service';
import { PopupService } from 'src/app/share/services/popup.service';
import { EinkommensrechnerComponent } from '../einkommensrechner.component';
import { confirm } from 'devextreme/ui/dialog';

export interface ekHaushalt {
  hhaId: number;
  hhaAngehoerige: hhaAngehoerige[];
}

export interface hhaAngehoerige {
  hhaAngehoerigerId: number;
  name: string;
}

export interface ekBerechnungen {
  id: number;
  hhaId: number;
  typ: number;
  datumBerechnung: Date;
  datumNachweis?: Date;
  betragJahr: number;
  betragMonat: number;
  bemerkung: string;
  rechtsgrundlage: number;
  ekPersonId_1: number;
  ekPersonId_2: number;
  ekPersonId_3: number;
  einkommenDetails: ekDetails[];
}

export interface ekDetails {
  einkommensartId: number;
  einkommenstyp: string;
  betrag_1: number;
  betrag_2: number;
  betrag_3: number;
  frequenz: number;
}

@Component({
  selector: 'app-einkommensrechner-search',
  templateUrl: './einkommensrechner-search.component.html',
  styleUrls: ['./einkommensrechner-search.component.scss'],
})
export class EinkommensrechnerSearchComponent {
  @ViewChild('ekrechnerDataGrid', { static: false }) ekrechnerDataGrid: DxDataGridComponent;
  @ViewChild('ekRechner', { static: false }) ekRechner: EinkommensrechnerComponent;

  @Input() set ekBerechnungen(ekb: ekBerechnungen[]) {
    if (ekb !== null && ekb !== undefined && ekb.length > 0) {
      this.load(ekb);
    } else {
      this.dataSource = [];
    }
  }

  @Input() ekHaushalt: ekHaushalt;

  @Output() onUpdate: EventEmitter<ekBerechnungen[]> = new EventEmitter<ekBerechnungen[]>();

  dataSource: any[];
  old_dataSource: string;

  isEkRechnerOpen: boolean = false;
  dsRechtsgrundlagen: any[] = this.configService.getEinkommenRechtsgrundlagen();

  constructor(private apiService: ApiService, public configService: ConfigService, public popupService: PopupService) {
    this.load = this.load.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onEkRechnerClose = this.onEkRechnerClose.bind(this);
  }

  onToolbarPreparing(e) {
    var searchPanel = e.toolbarOptions.items.filter((item) => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }
    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      cssClass: 'datagridToolbarNewBtn',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'add',
        text: 'Einkommensberechnung hinzufügen',
        onClick: this.onClickNew.bind(this),
      },
    });
  }

  // load(haushaltId: number) {
  //   this.apiService.post<any>(RouteKeys.Bgb.ek_berechnungen_search, { id: haushaltId }).subscribe(
  //     (response) => {
  //       this.dataSource = response;
  //       console.debug(response);
  //       this.old_dataSource = JSON.stringify(this.dataSource);
  //       this.ekrechnerDataGrid.instance.refresh();
  //     },
  //     (err) => {
  //       throw err.message;
  //     }
  //   );
  // }

  load(ekb) {
    setTimeout(() => {
      this.dataSource = ekb;
      this.old_dataSource = JSON.stringify(this.dataSource);
      this.ekrechnerDataGrid.instance.refresh();
    }, 50);
  }

  onClickNew(e) {
    this.isEkRechnerOpen = true;
    setTimeout(() => {
      this.ekRechner.formData = {
        hhaId: this.ekHaushalt.hhaId,
        hhaPersonen: this.ekHaushalt.hhaAngehoerige,
        modus: 'add',
        ekBerechnung: {
          id: null,
          datumBerechnung: new Date(),
          datumNachweis: null,
          rechtsgrundlage: null,
          ekDetails: [],
          bemerkung: '',
          ekPersonId_1: null,
          ekPersonId_2: null,
          ekPersonId_3: null,
        },
      };
      this.ekRechner.showEkRechner();
    }, 50);
  }

  onClickEdit(e) {
    const ek = e.row ? e.row.data : e.data;
    this.isEkRechnerOpen = true;
    setTimeout(() => {
      this.ekRechner.formData = {
        hhaId: this.ekHaushalt.hhaId,
        hhaPersonen: this.ekHaushalt.hhaAngehoerige,
        modus: 'edit',
        ekBerechnung: {
          id: ek.id,
          datumBerechnung: ek.datumBerechnung,
          datumNachweis: ek.datumNachweis,
          rechtsgrundlage: ek.rechtsgrundlage,
          ekDetails: ek.einkommenDetails,
          bemerkung: ek.bemerkung,
          ekPersonId_1: ek.ekPersonId_1,
          ekPersonId_2: ek.ekPersonId_2,
          ekPersonId_3: ek.ekPersonId_3,
        },
      };
      this.ekRechner.showEkRechner();
    }, 50);
  }

  onClickDelete(e) {
    let result = confirm('<i>Wollen Sie die Einkommensberechnung wirklich löschen?</i>', 'Bestätigung');
    result.then((dialogResult) => {
      if (dialogResult) {
        setTimeout(() => {
          this.dataSource.splice(e.row.rowIndex, 1);
        }, 150);
      }
    });
  }

  onEkRechnerClose(ekObj) {
    if (ekObj !== null && ekObj !== undefined) {
      if (ekObj.modus === 'add') {
        this.dataSource.push({
          datumBerechnung: ekObj.ekBerechnung.datumBerechnung,
          datumNachweis: ekObj.ekBerechnung.datumNachweis,
          rechtsgrundlage: ekObj.ekBerechnung.rechtsgrundlage,
          einkommenDetails: ekObj.ekBerechnung.ekDetails,
          bemerkung: ekObj.ekBerechnung.bemerkung,
          betragJahr: ekObj.ekBerechnung.betragJahr,
          betragMonat: ekObj.ekBerechnung.betragMonat,
          ekPersonId_1: ekObj.ekBerechnung.ekPersonId_1,
          ekPersonId_2: ekObj.ekBerechnung.ekPersonId_2,
          ekPersonId_3: ekObj.ekBerechnung.ekPersonId_3,
        });
      } else if (ekObj.modus === 'edit') {
        let item = this.dataSource.find((x) => x.id === ekObj.ekBerechnung.id);
        if (item !== null && item !== undefined) {
          item.datumBerechnung = ekObj.ekBerechnung.datumBerechnung;
          item.datumNachweis = ekObj.ekBerechnung.datumNachweis;
          item.rechtsgrundlage = ekObj.ekBerechnung.rechtsgrundlage;
          item.einkommenDetails = ekObj.ekBerechnung.ekDetails;
          item.bemerkung = ekObj.ekBerechnung.bemerkung;
          item.betragJahr = ekObj.ekBerechnung.betragJahr;
          item.betragMonat = ekObj.ekBerechnung.betragMonat;
          item.ekPersonId_1 = ekObj.ekBerechnung.ekPersonId_1;
          item.ekPersonId_2 = ekObj.ekBerechnung.ekPersonId_2;
          item.ekPersonId_3 = ekObj.ekBerechnung.ekPersonId_3;
        }
      }
      this.isEkRechnerOpen = false;
      this.onUpdate.emit(this.dataSource);
    }
  }
}
