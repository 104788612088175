import { Component, ViewChild, OnDestroy } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { ApiService } from 'src/app/share/services/api.service';
import { PopupService } from '../../services/popup.service';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { AuthService } from '../../services/auth.service';
import {  Router } from '@angular/router';

@Component({
  selector: 'app-loginCodePopup',
  templateUrl: './loginCodePopup.component.html',
  styleUrls: ['./loginCodePopup.component.scss']
})
export class LoginCodePopupComponent implements OnDestroy {

  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('vorlagenDataGrid', { static: false }) vorlagenDataGrid: DxDataGridComponent;
  @ViewChild('form', { static: false }) form: DxFormComponent;

  isLoading: boolean = false;
  error: string = null;
  onClose: (e) => void;
  formData: any = {
    username: "",
    password: "",
    verifyCode: ""
  };

  subscription_get_PopupAsObservable: Subscription;

  constructor(private apiService: ApiService, private popupService: PopupService, private router: Router , private authService: AuthService) {
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickOk = this.onClickOk.bind(this);

    this.subscription_get_PopupAsObservable = this.popupService.get_loginCodePopupAsObservable.subscribe(context => {
      if (context !== null && context !== undefined && context.open === true) {
        this.onClose = context.closeCb;
        this.formData.username = context.data.username;
        this.formData.password = context.data.password;
        this.popup.instance.show();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  onContentReady(e) {
    this.vorlagenDataGrid.instance.clearSelection();

    e.component.columnOption('command:select', {
      visibleIndex: 1,
      fixedPosition: 'right',
      visibleWidth: 70
    });
  }

  onToolbarPreparing(e) {
    var searchPanel = e.toolbarOptions.items.filter(item => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }
  }

  onClickClose(e) {
    this.isLoading = false;
    this.popup.instance.hide();
  }

  onClickOk(e){
    this.isLoading = true;
    this.authService.login(this.formData.username, this.formData.password, this.formData.verifyCode, () => {
      if (this.authService.isLoggedIn()) {
        this.router.navigate(['depart']).then(x => {
          if (x === false) {
            this.error = 'Fehler bei der Anmeldung, bitte kontaktieren Sie den Admninistrator!';
            this.isLoading = false;
          }
          else{
            this.popup.instance.hide();
          }
        });
      }
      else {
        this.error = 'Der Code ist falsch!';
        this.isLoading = false;
      }
    }, (err) => {
      this.isLoading = false;
      if (err.status === 403) {
        this.error = 'Der Code ist falsch!';
      }
      else {
        this.error = 'Server-Fehler!';
        throw err;
      }
    });
    this.isLoading = false;
    
    
  }
}