import { Component } from '@angular/core';
import DateBox from 'devextreme/ui/date_box';
import TextBox from 'devextreme/ui/text_box';
import SelectBox from 'devextreme/ui/select_box';
import TagtBox from 'devextreme/ui/tag_box';
import NumberBox from 'devextreme/ui/number_box';
import DataGrid from 'devextreme/ui/data_grid';
import DxForm from 'devextreme/ui/form';
import Button from 'devextreme/ui/button';
import Popup from 'devextreme/ui/popup';
import config from 'devextreme/core/config';
import { confirm } from 'devextreme/ui/dialog';
import { LanguageService } from './share/services/language.service';
import { ApiService } from './share/services/api.service';
import { RouteKeys } from './share/helper/route-keys.helper';
import dxToast from 'devextreme/ui/toast';
import { loadMessages, locale } from 'devextreme/localization';
import RELocalization from './share/localization/dx-rich.de.json';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  isInit: boolean = true;

  constructor(private languageService: LanguageService, private apiService: ApiService) {
    this.getVersion();
    this.languageService.init();
    this.setGlobalConfig();
    loadMessages({ de: RELocalization });
    locale("de");    
  }

  getVersion(){
    this.apiService.post<any>(RouteKeys.Config.get_version, null).subscribe(response => {
      console.info('CZ-Kita-Verwaltung:', response.version);
    });
  }

  setGlobalConfig() {
    config({
      editorStylingMode: 'outlined', // 'filled' | 'outlined' | 'underlined'
      defaultCurrency: 'EUR',
      forceIsoDateParsing: true
    });

    NumberBox.defaultOptions({
      options: {
        showClearButton: true,
        min: 1,
        max: 31999,
        showSpinButtons: true
      }
    });

    SelectBox.defaultOptions({
      options: {
        searchEnabled: true,
        searchMode: 'startswith',
        searchTimeout: 200,
        showClearButton: true,
      }
    });

    TagtBox.defaultOptions({
      options: {
        showClearButton: true,
        showSelectionControls: true,
        applyValueMode: 'useButtons'
      }
    });

    TextBox.defaultOptions({
      options: {
        showClearButton: true,
        maxLength: 3999,
      }
    });

    DateBox.defaultOptions({
      options: {
        displayFormat: 'dd.MM.yyyy',
        type: 'date',
        pickerType: 'calendar',
        min: '1900/1/1',
        max: '2099/12/31',
        showClearButton: true,
        useMaskBehavior: true,
        dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ss'
      }
    });

    DataGrid.defaultOptions({
      options: {
        columnAutoWidth: true,
        showColumnLines: true,
        showRowLines: false,
        showBorders: false,
        rowAlternationEnabled: true,
        allowColumnReordering: true,
        allowColumnResizing: true,
        columnResizingMode: 'widget',
        width: 100+'%',
        loadPanel: {
          enabled: false
        },
        editing: {
          confirmDelete: false
        },
        onContentReady: (e) => {
          const child = e.element.querySelector('div[role="form"]');
          if (child) {
            const saveButton = e.element.querySelector('.dx-datagrid-form-buttons-container div[aria-label=\'Speichern\']') as Element;
            if (saveButton) {
              const saveButtonInstance = Button.getInstance(saveButton) as Button;
              if (saveButtonInstance) {
                saveButtonInstance.option('type', 'default');
                saveButtonInstance.option('icon', 'check');
              }
            }
            const cancelButton = e.element.querySelector('.dx-datagrid-form-buttons-container div[aria-label=\'Abbrechen\']') as Element;
            if (cancelButton) {
              const cancelButtonInstance = Button.getInstance(cancelButton) as Button;
              if (cancelButtonInstance) {
                cancelButtonInstance.option('type', 'default');
                cancelButtonInstance.option('icon', 'remove');
              }
            }
          }
        },
        onRowRemoving: (e) => {
          let res = confirm('<i>Wollen Sie diesen Datensatz wirklich löschen?</i>', 'Bestätigung');
          e.cancel = new Promise((resolve, reject) => {
            res.then((dialogResult) => {
              resolve(!dialogResult);
            });
          });
        },
        scrolling: {
          useNative: false,
          mode: 'virtual',
          showScrollbar: 'always'
        },
        stateStoring: {
          savingTimeout: 0
        }
      } as any
    });

    Button.defaultOptions({
      options: {
        focusStateEnabled: false,
        hoverStateEnabled: false
      }
    });

    Popup.defaultOptions({
      options: {
        shadingColor: 'rgba(200, 200, 200, 0.75)',
        onTitleRendered: (e) => {
          const toolbarItems = e.component.option().toolbarItems;

          const ja_btn = toolbarItems.find(x => x.options.text.toLowerCase() === 'ja');
          if (ja_btn) {
            ja_btn.options.type = 'default';
            ja_btn.options.icon = 'check';
          }

          const nein_btn = toolbarItems.find(x => x.options.text.toLowerCase() === 'nein');
          if (nein_btn) {
            nein_btn.options.type = 'default';
            nein_btn.options.icon = 'remove';
          }

          const speichern_btn = toolbarItems.find(x => x.options.text.toLowerCase() === 'speichern');
          if (speichern_btn) {
            speichern_btn.options.type = 'default';
            speichern_btn.options.icon = 'check';
          }

          const abbruch_btn = toolbarItems.find(x => x.options.text.toLowerCase() === 'abbrechen');
          if (abbruch_btn) {
            abbruch_btn.options.type = 'default';
            abbruch_btn.options.icon = 'remove';
          }
        }
      } as any
    });

    DxForm.defaultOptions({
      options: {
        showColonAfterLabel: false,
        labelLocation: 'top'
      }
    });

    dxToast.defaultOptions({
      options: {
        width: '320px',
        displayTime: '3000',
        position: {
          at: 'bottom center',
          my: 'bottom center',
          offset: '0 -40',
          //of: '#app-table-container'
        }
    }
    });
  }
}

