import { Component, Input } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
  selector: 'app-columnChooser',
  templateUrl: './columnChooser.component.html',
  styleUrls: ['./columnChooser.component.scss']
})
export class ColumnChooserComponent {

  @Input() dataGrid : DxDataGridComponent;
  popupVisible: boolean;
  shadow_columns: any[] = [];

  constructor() {
    this.onClickUebernehmen = this.onClickUebernehmen.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
   }

  open() {
    this.popupVisible = true;
  }

  onShowingPopup(e) {
    this.shadow_columns = [];
    for (var i = 0; i < this.dataGrid.instance.columnCount(); i++) {
      var c = this.dataGrid.instance.columnOption(i);
      if (c.caption !== undefined && c.caption !== null && c.caption.length > 0 && c.showInColumnChooser === true) {
        this.shadow_columns.push({
          key: c.caption,
          name: c.caption,
          visible: c.visible
        });
      }
    }
  }

  onHiddenPopup(e) {
    this.shadow_columns = [];
  }

  onClickUebernehmen(e) {
    for (var i = 0; i < this.shadow_columns.length; i++) {
      this.dataGrid.instance.columnOption(this.shadow_columns[i].key, 'visible', this.shadow_columns[i].visible);
    }
    this.popupVisible = false;
  }

  onClickClose(e) {
    this.popupVisible = false;
  }

  onClickAllOn() {
    for (var i = 0; i < this.shadow_columns.length; i++) {
      this.shadow_columns[i].visible = true;
    }
  }

  onClickAllOff() {
    for (var i = 0; i < this.shadow_columns.length; i++) {
      this.shadow_columns[i].visible = false;
    }
  }
}
