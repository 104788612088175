<dx-popup #popup [fullScreen]="true" [showTitle]="false" [dragEnabled]="false" [hideOnOutsideClick]="false"
  [showCloseButton]="false">
  <div *dxTemplate="let data of 'content'">

    <div #richContainer></div>

  </div>

  <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom"
    [options]="{ elementAttr: { id: 'docXToolbarButton' }, text: 'Schließen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose}">
  </dxi-toolbar-item>
</dx-popup>