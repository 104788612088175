import { Component, ElementRef, OnDestroy, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ContextMenuCommandId, create, createOptions, FileTabItemId, Options, Ribbon, RibbonTabType, RichEdit } from 'devexpress-richedit';
import { DocumentFormatApi } from 'devexpress-richedit/lib/model-api/formats/enum';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs/internal/Subscription';
import { PopupService } from '../../services/popup.service';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-docX-multi-preview-popup',
  templateUrl: './docX-multi-preview-popup.component.html',
  styleUrls: ['./docX-multi-preview-popup.component.scss'],
})
export class DocxMultiPreviewPopupComponent implements OnDestroy {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('richContainer') richContainerElement: ElementRef;
  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;

  @Input() type: string = null;

  @Output() onPlatzhalter = new EventEmitter<string>();

  onClose: (e, data: any) => void;

  docs: any[] = [];
  currentDocIndex: number = 0;
  currentDocName: string = '';
  docType: string = '';
  imageSrc: any;
  textSrc: SafeHtml = '';
  fileContent: any;
  isReadOnly: boolean = true;
  isVorlage: boolean = false;
  isLoading: boolean = false;

  private rich: RichEdit;

  subscription_get_PopupAsObservable: Subscription;

  constructor(private apiService: ApiService, private sanitizer: DomSanitizer, private popupService: PopupService) {
    this.onClickClose = this.onClickClose.bind(this);
    this.load = this.load.bind(this);
    this.openBlob = this.openBlob.bind(this);
    this.onClickPlatzhalterliste = this.onClickPlatzhalterliste.bind(this);

    setTimeout(() => {
      if (this.type !== 'Vorlage') {
        this.subscription_get_PopupAsObservable = this.popupService.get_dokumentEditPopupAsObservable.subscribe((context) => {
          if (context !== null && context !== undefined && context.open === true) {
            this.type = context.data.type !== null ? context.data.type : this.type;
            this.onClose = context.closeCb;
            this.isReadOnly = context.data.readOnly;

            if (!this.isReadOnly && context.data.doc !== undefined) {
              this.openDoc(context.data.doc);
            } else if (context.data.doc !== undefined) {
              this.load(context.data.doc);
            }
          }
        });
      }
    }, 10);
  }

  ngOnDestroy() {
    if (this.rich) {
      this.rich.dispose();
      this.rich = null;
    }
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  openBlob(blob: Blob, fileName: string, format: number) {
    this.isLoading = true;
    this.popup.instance.show().then(() => {
      if (!this.rich) {
        this.rich = create(this.richContainerElement.nativeElement, this.getOptions(fileName));
      }
      this.rich.readOnly = this.isReadOnly;
      this.rich.openDocument(blob, fileName, format, (e) => {
        if (!this.isReadOnly) {
          setTimeout(() => {
            this.rich.hasUnsavedChanges = true;
          }, 50);
        }
        this.isLoading = false;
      });
    });
  }

  openDoc(doc: any) {
    if (doc !== null && doc !== undefined) {
      this.currentDocName = doc.docName;
      this.docType = 'doc';
      this.openBlob(doc.blob, doc.docName, DocumentFormatApi.OpenXml);
    }
  }

  openMulti(docs: any[], index: number = 0) {
    this.currentDocName = '';
    this.docs = docs || [];
    this.currentDocIndex = index;
    this.docType = '';
    this.imageSrc = null;
    this.textSrc = null;
    this.isReadOnly = true;

    if (this.docs.length > 0) {
      this.load(this.docs[index]);
    }
  }

  load(doc: any) {
    this.currentDocName = doc.fileName;
    if (doc.dokumentId === null || doc.dokumentId === undefined || doc.dokumentId < 0) {
      return;
    }
    if (doc.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.docType = 'doc';
      this.apiService.postDownloadAsBlob(
        RouteKeys.Dokumente.download,
        doc.fileName,
        { type: this.type, dokumentId: doc.dokumentId, fileName: doc.fileName },
        (blob) => {
          this.openBlob(blob, doc.fileName, DocumentFormatApi.OpenXml);
        },
        (err) => this.errorMessage(err)
      );
    } else if (doc.mimeType === 'application/msword') {
      this.docType = 'doc';
      this.apiService.postDownloadAsBlob(
        RouteKeys.Dokumente.download,
        doc.fileName,
        { type: this.type, dokumentId: doc.dokumentId, fileName: doc.fileName },
        (blob) => {
          this.openBlob(blob, doc.fileName, DocumentFormatApi.Rtf);
        },
        (err) => this.errorMessage(err)
      );
    } else if (doc.mimeType === 'application/pdf') {
      this.apiService.postDownloadAsBlob(
        RouteKeys.Dokumente.download,
        doc.fileName,
        { type: this.type, dokumentId: doc.dokumentId, fileName: doc.fileName },
        (blob) => {
          this.docType = 'pdf';
          // this.popup.instance.show().then(() => {
          //   setTimeout(() => {
          //     this.pdfViewer.pdfSrc = blob;
          //     this.pdfViewer.refresh();
          //   }, 10);
          // });
          this.popup.instance.show();
          setTimeout(() => {
            this.pdfViewer.pdfSrc = blob;
            this.pdfViewer.refresh();
          }, 10);
        },
        (err) => this.errorMessage(err)
      );
    } else if (doc.mimeType.startsWith('image/')) {
      this.apiService.postDownloadAsObjectURL(
        RouteKeys.Dokumente.download,
        doc.fileName,
        { type: this.type, dokumentId: doc.dokumentId, fileName: doc.fileName },
        (objectURL) => {
          this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          this.docType = 'image';
          this.popup.instance.show();
        },
        (err) => this.errorMessage(err)
      );
    } else if (doc.mimeType.startsWith('text/')) {
      this.apiService.postDownloadAsBlob(
        RouteKeys.Dokumente.download,
        doc.fileName,
        { type: this.type, dokumentId: doc.dokumentId, fileName: doc.fileName },
        (blob) => {
          this.docType = 'text';
          blob.text().then((x) => {
            this.textSrc = this.sanitizer.bypassSecurityTrustHtml(x.replace(/(?:\r\n|\r|\n)/g, '<br>'));
            this.popup.instance.show();
          });
        },
        (err) => this.errorMessage(err)
      );
    } else {
      this.docType = null;
    }
  }

  onClickClose() {
    this.fileContent = null;
    if (!this.isReadOnly && this.rich !== null && this.rich !== undefined) {
      this.rich.hasUnsavedChanges = false;
    }
    this.popup.instance.hide();
  }

  getOptions(fileName: string): Options {
    const options = createOptions();
    options.width = '100%';
    options.height = '100%';
    options.readOnly = this.isReadOnly;

    const fileTab = options.ribbon.getTab(RibbonTabType.File);
    fileTab.removeItem(FileTabItemId.CreateNewDocument);
    fileTab.removeItem(FileTabItemId.OpenDocument);
    // fileTab.removeItem(FileTabItemId.ExportDocument);

    // options.ribbon.clearTabs();
    // options.ribbon.insertTab(fileTab, 0);
    options.ribbon.activeTabIndex = 0;

    this.removeContextMenuItems(options);

    options.events.saving = (s, e) => {
      e.handled = true;
      this.fileContent = e;
      if (this.fileContent !== null && this.fileContent !== undefined) {
        if (this.onClose !== undefined && this.onClose !== null) {
          this.onClose({ docContentBase64: this.fileContent.base64, docName: this.currentDocName }, true);
        }
      }
      this.popup.instance.hide();
    };
    return options;
  }

  removeContextMenuItems(options: Options) {
    for (let index = 0; index < options.contextMenu.items.length; index++) {
      const element = options.contextMenu.items[index];
      if (element.id !== ContextMenuCommandId.Copy) {
        options.contextMenu.removeItem(element);
      }
    }

    if (options.contextMenu.items.length > 1) {
      this.removeContextMenuItems(options);
    }
  }

  loadVorlage(doc: any) {
    this.isVorlage = true;
    this.currentDocName = doc.fileName;
    this.isReadOnly = false;
    if (doc.vorlagenId > 0) {
      this.docType = 'doc';
      this.apiService.postDownloadAsBlob(
        RouteKeys.Option.Vorlagenverwaltung.download,
        doc.fileName,
        { type: this.type, vorlagenId: doc.vorlagenId, fileName: doc.fileName },
        (blob) => {
          this.openBlob(blob, doc.fileName, DocumentFormatApi.OpenXml);
        }
      );
    } else {
      this.docType = null;
    }
  }

  onClickPlatzhalterliste(e) {
    this.onPlatzhalter.emit('');
  }

  private errorMessage(err: any) {
    if (err) {
      notify(
        {
          message: 'Die Datei wurde nicht gefunden!',
        },
        'error',
        4000
      );
    }
  }
}
