import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './share/guards/auth.guard';

import('./department/department.module').then(m => m.DepartmentModule)
import('./department/department-inquires/department-inquires.module').then(m => m.DepartmentInquiresModule);
import('./department/department-stammdaten/department-stammdaten.module').then(m => m.DepartmentStammdatenModule);
import('./department/department-recherche/department-recherche.module').then(m => m.DepartmentRechercheModule);
import('./department/department-wiedervorlagen/department-vorlagenverwaltung.module').then(m => m.WiedervorlagenModule);
import('./department/department-optionen/department-optionen.module').then(m => m.DepartmentOptionenModule);
import('./department/department-optionen/department-users/department-users.module').then(m => m.DepartmentUsersModule);
import('./department/department-optionen/department-vorlagenverwaltung/department-vorlagenverwaltung.module').then(m => m.DepartmentVorlagenverwaltungModule);
import('./department/bgb/bgb.module').then(m => m.BgbModule);

const routes: Routes = [
  { path: '', redirectTo: 'extern', pathMatch: 'full' },
  {
    path: 'extern', loadChildren: () => import('./extern/extern.module').then(m => m.ExternModule)
  },
  {
    path: 'depart', loadChildren: () => import('./department/department.module').then(m => m.DepartmentModule), canActivate: [AuthGuard], canLoad: [AuthGuard]
  },
  {
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: true, preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
