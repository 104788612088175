import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ISidebarMenu } from '../interfaces/SidebarMenu/ISidebarMenu';

@Injectable({
  providedIn: 'root',
})
export class MenuSidebarService {
  private _sidebarMenu: BehaviorSubject<any> = new BehaviorSubject(null);
  sidebarMenuAsObservable: Observable<any> = this._sidebarMenu.asObservable();

  private _rerender: BehaviorSubject<any> = new BehaviorSubject(null);
  rerenderAsObservable: Observable<any> = this._rerender.asObservable();

  constructor() {}

  rerender() {
    this._rerender.next(true);
  }

  getSidebarMenuAsObservable(): Observable<any> {
    return this.sidebarMenuAsObservable;
  }

  createMenu(menu: ISidebarMenu) {
    this._sidebarMenu.next(menu);
  }

  changeMenu(data, itemUrl) {
    data.menuItems.forEach((item) => {
      if (item.path.url === itemUrl) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      if (item.items.length > 0) {
        item.items.forEach((subItem) => {
          if (subItem.path.url === itemUrl) {
            subItem.isSelected = true;
          } else {
            subItem.isSelected = false;
          }
        });
      }
    });
  }

  changeMenuByName(data, itemKey, itemName) {
    data.menuItems.forEach((item) => {
      item.isSelected = false;
      if (item.items.length > 0) {
        item.items.forEach((subItem) => {
          subItem.isSelected = false;
        });
      }
    });

    const thisItem = data.menuItems.find((x) => x.key === itemKey);
    if (thisItem) {
      thisItem.isSelected = true;
      if (thisItem.items.length > 0) {
        const thisSubItem = thisItem.items.find((y) => y.name === itemName);
        if (thisSubItem) {
          thisSubItem.isSelected = true;
        }
      }
    }
  }

  changeMenuByKey(data, itemKey) {
    data.menuItems.forEach((item) => {
      if (item.key === itemKey) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
      if (item.items.length > 0) {
        item.items.forEach((subItem) => {
          if (subItem.key === itemKey) {
            subItem.isSelected = true;
          } else {
            subItem.isSelected = false;
          }
        });
      }
    });
  }
}
