<div *ngIf="setHeader1 === true" class="frame-container-header1 dx-theme-accent-as-text-color">
    <div class="frame-container-header-top">
        <ng-content select="[header-top]"></ng-content>
    </div>
</div>

<div *ngIf="setHeader2 === true" class="frame-container-header2 dx-theme-accent-as-text-color">
    <div class="frame-container-header-left">
        <ng-content select="[header-left]"></ng-content>
    </div>

    <div class="frame-container-header-center">
        <ng-content select="[header-center]"></ng-content>
    </div>

    <div class="frame-container-header-right">
        <ng-content select="[header-right]"></ng-content>
    </div>
</div>

<div class="frame-container-body">
    <ng-content select="[body]"></ng-content>
</div>

<div *ngIf="setFooter === true" class="frame-container-footer">
    <ng-content select="[body-bottom]"></ng-content>

    <div class="frame-container-footer-left">
        <ng-content select="[footer-left]"></ng-content>
    </div>

    <div class="frame-container-footer-center">
        <ng-content select="[footer-center]"></ng-content>
    </div>

    <div class="frame-container-footer-right">
        <ng-content select="[footer-right]"></ng-content>
    </div>
</div>