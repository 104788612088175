<dx-data-grid
  class="dokumente-datagrid"
  #dokumenteDataGrid
  [dataSource]="dataSource"
  height="auto"
  [columnAutoWidth]="true"
  (onSelectionChanged)="onSelectionChanged($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onContentReady)="onContentReady($event)"
  (onRowUpdating)="updateRow($event)"
  (onRowDblClick)="onRowDblClick($event)"
>
  <dxo-remote-operations [filtering]="true" [paging]="true" [sorting]="true" [summary]="true" [grouping]="true" [groupPaging]="true">
  </dxo-remote-operations>

  <dxo-editing mode="form" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false">
    <dxo-form>
      <dxi-item dataField="bezeichnung" [label]="{ text: 'Bezeichnung' }"></dxi-item>
      <dxi-item
        dataField="dokumentenArtId"
        editorType="dxSelectBox"
        [isRequired]="false"
        [label]="{ text: 'Dokumentenart' }"
        [editorOptions]="{
          dataSource: configService.getKatalog('DokumentenArtKatalog'),
          valueExpr: 'id',
          displayExpr: 'bezeichnung',
          searchExpr: 'bezeichnung'
        }"
      >
      </dxi-item>
    </dxo-form>
  </dxo-editing>

  <dxo-selection selectAllMode="page" mode="single"></dxo-selection>

  <dxo-paging [pageSize]="30"></dxo-paging>
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-header-filter [visible]="false"></dxo-header-filter>
  <dxo-grouping #expand [autoExpandAll]="false"></dxo-grouping>
  <dxo-scrolling mode="infinite" showScrollbar="onHover"></dxo-scrolling>

  <dxo-search-panel [width]="300" [visible]="true"> </dxo-search-panel>

  <dxi-column *ngIf="type==='Haushalt'" dataField="bereich" caption="Bereich"></dxi-column>
  <dxi-column dataField="bezeichnung" caption="Bezeichnung"></dxi-column>
  <dxi-column dataField="fileName" caption="Dateiname"></dxi-column>
  <dxi-column dataField="datum" caption="Datum" [width]="150" [calculateDisplayValue]="calculateDatum"> </dxi-column>
  <dxi-column dataField="dokumentenArtId" caption="Dokumentenart">
    <dxo-lookup [dataSource]="configService.getKatalog('DokumentenArtKatalog')" displayExpr="bezeichnung" valueExpr="id"> </dxo-lookup>
  </dxi-column>

  <dxi-column dataField="istVomBuerger" caption="Bürger-Upload" dataType="boolean" [width]="130"></dxi-column>

  <dxi-column
    type="buttons"
    [width]="150"
    [allowReordering]="false"
    [allowResizing]="false"
    [allowHiding]="false"
    [fixed]="true"
    [showInColumnChooser]="false"
  >
    <dxi-button *ngIf="!readOnly" hint="Vorschau" icon="search" [onClick]="onClickVorschau" [disabled]="checkIsVorschauDisabled"></dxi-button>
    <dxi-button hint="Bearbeiten" icon="edit" [onClick]="onClickEdit" *ngIf="!readOnly" [disabled]="isNotHaushalt"></dxi-button>
    <dxi-button hint="Download" icon="download" [onClick]="onClickDownload" [disabled]="isDisabled"></dxi-button>
    <dxi-button hint="Löschen" icon="trash" [onClick]="onClickDelete" *ngIf="!readOnly" [disabled]="isNotHaushalt"></dxi-button>
  </dxi-column>
</dx-data-grid>

<dokumente-edit (onAddClose)="onAddClose($event)" #dokumenteEditPopup></dokumente-edit>
