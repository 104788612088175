import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { Subscription } from 'rxjs';
import { PopupService } from '../../services/popup.service';
import DataSource from 'devextreme/data/data_source';
import { ApiService } from '../../services/api.service';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ConfigService } from '../../services/config.service';
import { ColumnChooserComponent } from '../columnChooser/columnChooser.component';

@Component({
  selector: 'app-bgb-person-search-popup',
  templateUrl: './bgb-person-search-popup.component.html',
  styleUrls: ['./bgb-person-search-popup.component.scss'],
})
export class BgbPersonSearchPopupComponent implements OnInit, OnDestroy {
  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('dataGrid', { static: false }) dataGrid: DxDataGridComponent;
  @ViewChild('columnChooser', { static: false }) columnChooser: ColumnChooserComponent;

  subscription_get_PopupAsObservable: Subscription;
  contextData: any;
  onClose: (e) => void;
  init: boolean = false;
  isLoading: boolean = false;
  selectedItem: any = null;
  dataSource: DataSource;

  focusedRowKey: number;

  constructor(private popupService: PopupService, private apiService: ApiService, public configService: ConfigService) {
    this.onClickOk = this.onClickOk.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickEdit = this.onClickEdit.bind(this);

    this.subscription_get_PopupAsObservable = this.popupService.get_bgbPersonSearchPopupAsObservable.subscribe((e) => this.onPersonSearchEvent(e));
  }

  ngOnInit(): void {
    this.dataSource = new DataSource({
      key: 'id',
      load: (loadOptions) => {
        loadOptions.userData.selector = this.contextData.selector;
        loadOptions.userData.haushaltId = this.contextData.hhId;
        return this.apiService
          .post<any>(RouteKeys.Bgb.person_search, loadOptions)
          .toPromise()
          .then((response) => {
            this.init = true;
            return {
              data: response.data,
              totalCount: response.totalCount,
              summary: response.summary,
              groupCount: response.groupCount,
            };
          })
          .catch((err) => {
            throw err.message;
          });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.subscription_get_PopupAsObservable) {
      this.subscription_get_PopupAsObservable.unsubscribe();
    }
  }

  onClickOk(e) {
    if (this.onClose !== undefined && this.onClose !== null) {
      this.onClose({
        status: 1,
        selectedItem: this.selectedItem,
      });
    }
    this.dataGrid.instance.clearSelection();
    this.popup.instance.hide();
  }

  onClickClose(e) {
    if (this.onClose !== undefined && this.onClose !== null) {
      this.onClose({
        status: 2,
      });
    }
    this.dataGrid.instance.clearSelection();
    this.popup.instance.hide();
  }

  onPersonSearchEvent(e: any): void {
    if (e !== null && e !== undefined && e.open === true) {
      this.onClose = e.closeCb;
      this.contextData = e.data;
      this.selectedItem = null;

      setTimeout(() => {
        this.init = true;
      }, 0);

      if (this.dataGrid) {
        this.dataGrid.instance.clearFilter();
        this.dataGrid.instance.clearSorting();
      }

      this.dataSource.reload();
      this.popup.instance.show();
    } else {
      this.contextData = null;
    }
  }

  onContentReady(e) {
    e.component.columnOption('command:select', {
      visibleIndex: 1,
      fixedPosition: 'right',
      visibleWidth: 70,
    });

    if (
      this.contextData !== undefined &&
      this.contextData !== null &&
      this.contextData.selected !== undefined &&
      this.contextData.selected !== null &&
      this.contextData.selected > 0
    ) {
      this.dataGrid.instance.selectRows([this.contextData.selected], false);
      this.focusedRowKey = this.contextData.selected;
    }
  }

  onToolbarPreparing(e) {
    const columnChooserButton = e.toolbarOptions.items.find((x) => x.name === 'columnChooserButton');
    if (columnChooserButton) {
      columnChooserButton.locateInMenu = 'never';
      columnChooserButton.showText = 'never';
      columnChooserButton.options.visible = false;
    }

    var searchPanel = e.toolbarOptions.items.filter((item) => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }

    e.toolbarOptions.items.push({
      location: 'before',
      widget: 'dxButton',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'undo',
        hint: 'Filter zurücksetzen',
        onClick: (event) => {
          this.dataGrid.instance.clearFilter();
          this.dataGrid.instance.clearSorting();
          this.dataGrid.instance.clearSelection();
        },
      },
    });

    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      options: {
        stylingMode: 'contained',
        type: 'default',
        icon: 'columnchooser',
        hint: 'Spaltenauswahl',
        onClick: (e) => {
          this.columnChooser.open();
        },
      },
    });

    // e.toolbarOptions.items.push({
    //   location: 'after',
    //   widget: 'dxButton',
    //   options: {
    //     stylingMode: 'contained',
    //     type: 'default',
    //     icon: 'add',
    //     text: 'Neu',
    //     onClick: this.onClickNew.bind(this),
    //   },
    // });
  }

  onSelectionChanged(e) {
    var deselectRowKeys = [];
    if (e.selectedRowKeys && e.selectedRowKeys[0]) {
      let rowData = e.selectedRowsData[0];
      if (rowData !== undefined && rowData !== null) {
        if (this.contextData !== undefined && this.contextData !== null) {
          if (this.contextData.blocked.includes(rowData.id)) {
            deselectRowKeys.push(e.selectedRowKeys[0]);
          } else {
            this.selectedItem = rowData;
          }
        }
      }
    }

    if (deselectRowKeys.length) {
      this.selectedItem = null;
      e.component.deselectRows(deselectRowKeys);
    }
  }

  onClickNew(e) {
    this.popupService.showBgbPersonEditPopup({ isNew: true }, (e) => {
      this.handleEditCloseEvent(e);
    });
  }

  onClickEdit(e) {
    if (e.row !== undefined && e.row !== null) {
      this.popupService.showBgbPersonEditPopup({ isNew: false, entityId: e.row.data.id }, (e) => {
        this.handleEditCloseEvent(e);
      });
    } else {
      this.popupService.showBgbPersonEditPopup({ isNew: false, entityId: e.data.id }, (e) => {
        this.handleEditCloseEvent(e);
      });
    }
  }

  handleEditCloseEvent(e: any) {
    if (e.state === 1) {
      this.dataGrid.instance.refresh().then(() => {
        this.dataGrid.instance.selectRows([e.data.id], false);
        this.selectedItem = this.dataGrid.instance.getSelectedRowsData()[0];
        this.focusedRowKey = this.dataGrid.instance.getSelectedRowKeys()[0];
      });
    }
  }

  onRowPrepared(e) {
    if (e.rowType === 'data') {
      if (
        this.contextData !== undefined &&
        this.contextData !== null &&
        this.contextData.blocked !== undefined &&
        this.contextData.blocked !== null &&
        this.contextData.blocked.length > 0
      ) {
        if (this.contextData.blocked.includes(e.data.id)) {
          e.rowElement.style.color = 'rgb(128 128 128 / 75%)';
        }
      }
    }
  }
}
