import { Component, ElementRef, OnDestroy, ViewChild, Input } from '@angular/core';
import { ContextMenuCommandId, create, createOptions, FileTabItemId, Options, Ribbon, RibbonTabType, RichEdit } from 'devexpress-richedit';
import { DocumentFormatApi } from 'devexpress-richedit/lib/model-api/formats/enum';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';

@Component({
  selector: 'app-docX-preview-popup',
  templateUrl: './docX-preview-popup.component.html',
  styleUrls: ['./docX-preview-popup.component.scss']
})
export class DocxPreviewPopupComponent implements OnDestroy {

  @ViewChild('popup', { static: false }) popup: DxPopupComponent;
  @ViewChild('richContainer') richContainerElement: ElementRef;


  private rich: RichEdit;

  constructor() {
    this.onClickOk = this.onClickOk.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
  }

  ngOnDestroy() {
    if (this.rich) {
      this.rich.dispose();
      this.rich = null;
    }
  }

  openBlob(blob: Blob, fileName: string) {
    if (this.rich) {
      this.rich.updateRibbon((ribbon: Ribbon) => {
        const fileTab = ribbon.getTab(RibbonTabType.File);
        fileTab.title = fileName;
      })
      this.rich.openDocument(blob);
    }

    this.popup.instance.show().then(() => {
      if (!this.rich) {
        this.rich = create(this.richContainerElement.nativeElement, this.getOptions(fileName));
        this.rich.openDocument(blob, fileName, DocumentFormatApi.OpenXml);
      }
    });
  }

  onClickOk() {
    this.popup.instance.hide();
  }

  onClickClose() {
    this.popup.instance.hide();
  }

  getOptions(fileName: string): Options {
    const options = createOptions();
    options.width = '100%';
    options.height = '100%';
    options.confirmOnLosingChanges.enabled = false;
    options.readOnly = true;

    const fileTab = options.ribbon.getTab(RibbonTabType.File);
    fileTab.title = fileName;
    fileTab.removeItem(FileTabItemId.CreateNewDocument);
    fileTab.removeItem(FileTabItemId.OpenDocument);
    fileTab.removeItem(FileTabItemId.ExportDocument);

    options.ribbon.clearTabs();
    options.ribbon.insertTab(fileTab, 0);
    options.ribbon.activeTabIndex = 0;

    this.removeContextMenuItems(options);

    return options;
  }

  removeContextMenuItems(options: Options) {
    for (let index = 0; index < options.contextMenu.items.length; index++) {
      const element = options.contextMenu.items[index];
      if (element.id !== ContextMenuCommandId.Copy) {
        options.contextMenu.removeItem(element);
      }
    }

    if (options.contextMenu.items.length > 1) {
      this.removeContextMenuItems(options);
    }
  }
}
