import { AfterContentInit, AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { IMenuItem } from '../../interfaces/SidebarMenu/IMenuItem';
import { ISubMenuItem } from '../../interfaces/SidebarMenu/ISubMenuItem';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';
import { MenuSidebarService } from '../../services/menu-sidebar.service';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: 'menu-sidebar.component.html',
  styleUrls: ['menu-sidebar.component.scss'],
})
export class MenuSidebarComponent implements AfterContentInit, AfterViewInit {
  menuItems: any[] = [];
  selectedMenuItem: any;
  isInit: boolean;
  constructor(
    private router: Router,
    private sidebarService: MenuSidebarService,
    private authService: AuthService,
    private configService: ConfigService
  ) {}

  ngAfterContentInit(): void {
    this.sidebarService.getSidebarMenuAsObservable().subscribe((data) => {
      if (data !== null) {
        let result: any[] = [];
        data.menuItems.forEach((item) => {
          if (item.neededLicense && item.neededLicense.length > 0) {
            if (this.configService.hasLicense(item.neededLicense)) {
              if (this.checkRoles(item)) {
                this.checkSubItems(item);
                result.push(item);
              }
            }
          } else {
            if (this.checkRoles(item)) {
              this.checkSubItems(item);
              result.push(item);
            }
          }
        });
        this.menuItems = result;
      }
    });
  }

  private checkRoles(item) {
    if (item.neededRoles && item.neededRoles.length > 0) {
      if (this.authService.isInOneOfThisRoles(item.neededRoles)) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  checkSubItems(item: any) {
    let subItems: any[] = [];
    item.items.forEach((subItem) => {
      if (subItem.neededLicense && subItem.neededLicense.length > 0) {
        if (this.configService.hasLicense(subItem.neededLicense)) {
          if (this.checkRoles(subItem)) {
            subItems.push(subItem);
          }
        }
      } else {
        if (this.checkRoles(subItem)) {
          subItems.push(subItem);
        }
      }
    });
    item.items = subItems;
  }

  ngAfterViewInit(): void {
    const item = this.menuItems.find((x) => x.isSelected === true);
    if (item !== undefined && item !== null) {
      setTimeout(() => {
        item.isSelected = false;
      }, 5);
      setTimeout(() => {
        item.isSelected = true;
      }, 5);
      if (item) {
        this.selectedMenuItem = item;
        if (item.items.length > 0) {
          this.selectMenuWidthSubItems(item.key);
        }
      }
    }

    setTimeout(() => {
      // Falls es mehr als einen geöffneten Item gibt, muss hier eine Schleife durch alle geöffnete Items eingebaut werden
      const openItem = this.menuItems.find((x) => x.isOpen === true);
      if (openItem !== undefined && openItem !== null) {
        if (openItem.items.length > 0) {
          this.selectMenuWidthSubItems(openItem.key);
        }
      }
    }, 250);
  }

  onClickMenuItem(e, target: IMenuItem) {
    if (this.selectedMenuItem !== e.target || !target.isSelected) {
      this.deselectAllItems();
      target.isSelected = true;
      this.selectedMenuItem = target;
    }

    if (target.onClick !== undefined && target.onClick !== null) {
      target.onClick(target);
    }

    if (target.path !== undefined && target.path !== null) {
      this.navigateToUrlFromMenu(target.path);
    }
  }

  onClickSubmenuItem(e, target: IMenuItem, subItem: ISubMenuItem) {
    if ((this.selectedMenuItem && this.selectedMenuItem !== e.target) || !target.isSelected) {
      this.deselectAllItems();
      target.isSelected = true;
      subItem.isSelected = true;
    }
    this.selectedMenuItem = target;

    if (subItem.onClick !== undefined && subItem.onClick !== null) {
      subItem.onClick(subItem);
    }

    if (subItem.path !== undefined && subItem.path !== null) {
      this.navigateToUrlFromMenu(subItem.path);
    }
  }

  onClickMenuWithSubItems(e, target: IMenuItem) {
    if (target.onClick !== undefined && target.onClick !== null) {
      target.onClick(target);
    }

    this.selectMenuWidthSubItems(target.key);
  }

  selectMenuWidthSubItems(targetId) {
    const menuToToggle = document.getElementById(targetId);
    if (menuToToggle.style.display === 'block') {
      menuToToggle.style.display = 'none';

      const wrapperToToggleClosed = document.getElementById('wrapper-' + targetId + '-closed');
      wrapperToToggleClosed.style.display = 'flex';

      const wrapperToToggleOpen = document.getElementById('wrapper-' + targetId + '-open');
      wrapperToToggleOpen.style.display = 'none';
    } else {
      menuToToggle.style.display = 'block';

      const wrapperToToggleClosed = document.getElementById('wrapper-' + targetId + '-closed');
      wrapperToToggleClosed.style.display = 'none';

      const wrapperToToggleOpen = document.getElementById('wrapper-' + targetId + '-open');
      wrapperToToggleOpen.style.display = 'flex';
    }
  }

  navigateToUrlFromMenu(target) {
    if (target.param) {
      let paramKey = target.param.key;
      let paramValue = target.param.value;
      let paramObj = {};
      paramObj[paramKey] = paramValue;

      this.router.navigate([target.url], {
        queryParams: paramObj,
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([target.url]);
    }
  }

  deselectAllItems() {
    this.menuItems.forEach((item) => {
      item.isSelected = false;
      if (item.items.length > 0) {
        item.items.forEach((subItem) => {
          subItem.isSelected = false;
        });
      }
    });
  }
}
