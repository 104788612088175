import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouteKeys } from '../helper/route-keys.helper';
import { ApiService } from './api.service';


@Injectable()
export class ConfigExternService {

  public globalSettings: any[] = [];
  noConfigFoundWarning: string[] = [];
  isInit: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiService: ApiService) {    
  }

  init(): Observable<boolean> {
    this.load();
    return this.isInit.asObservable();
  }

  stop() {
    this.isInit.next(false);
  }

  reload() {
    this.load();
  }

  private load() {

    this.apiService.post<any>(RouteKeys.Config.get_config_extern, null).subscribe(response => {
      this.noConfigFoundWarning = [];   
      this.globalSettings = response.globalSettings;
      this.isInit.next(true);
    });
  }

  public getGlobalSetting(param: string): string {
    if (param !== '' && param !== undefined) {
      const result = this.globalSettings.find(x => x.key === param);
      if (result != null && result !== undefined) {
        return result.value;
      }
    }
    return '';
  }
}
