<dx-popup #popup [fullScreen]="false" [showTitle]="true" [dragEnabled]="true" [hideOnOutsideClick]="false"
    [showCloseButton]="true" [title]="'Wiedervorlage' + getVorgang()" width="800" height="auto">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view #scrollView height="100%">

            <dx-form #form [width]="'100%'" [formData]="formData" labelLocation="top" [colCount]="1">

                <dxi-item itemType="group" [colCount]="8">
                    <dxi-item dataField="faelligDatum" editorType="dxDateBox" [label]="{text: 'Fällig'}" [colSpan]="3">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item dataField="faelligMitarbeiterId" editorType="dxSelectBox" [label]="{text: 'Mitarbeiter'}"
                        [editorOptions]="{ dataSource: configService.getBenutzer(), valueExpr: 'id', displayExpr: 'bezeichnung', searchExpr: 'bezeichnung' }"
                        [colSpan]="5">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                </dxi-item>

                <dxi-item itemType="group" [colCount]="2">
                    <dxi-item dataField="betreff" [label]="{text: 'Betreff'}" [colSpan]="2">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                </dxi-item>

                <dxi-item itemType="group" [colCount]="2">
                    <dxi-item dataField="bemerkung" [label]="{text: 'Bemerkung'}" editorType="dxTextArea"
                        [editorOptions]="{ maxLength: 7999, height: 150  }" [colSpan]="2">
                    </dxi-item>
                </dxi-item>
            </dx-form>
        </dx-scroll-view>
    </div>

    <dxi-toolbar-item widget="dxButton" location="before" toolbar="bottom"
        [options]="{ visible: !isNew && canShowZumAntrag, text: 'Zum Antrag', type: 'default', stylingMode: 'contained',  icon: 'edit', onClick: onClickGeheZu }">
    </dxi-toolbar-item>

    <dxi-toolbar-item widget="dxButton" location="before" toolbar="bottom"
        [options]="{ visible: canFinish && !isNew, text: 'Erledigt', type: 'default', stylingMode: 'contained',  icon: 'check', disabled: isLoading, onClick: onClickErledigt }">
    </dxi-toolbar-item>

    <dxi-toolbar-item widget="dxButton" location="before" toolbar="bottom"
        [options]="{ visible: !canFinish && !isNew, text: 'Unerledigt', type: 'default', stylingMode: 'contained',  icon: 'remove', disabled: isLoading, onClick: onClickUnerledigt }">
    </dxi-toolbar-item>

    <!-- ------------------ -->

    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Speichern', type: 'default', stylingMode: 'contained',  icon: 'save', disabled: isLoading, onClick: onClickSave }">
    </dxi-toolbar-item>
    <dxi-toolbar-item widget="dxButton" location="after" toolbar="bottom"
        [options]="{ text: 'Abbrechen', type: 'default', stylingMode: 'contained', icon: 'remove', onClick: onClickClose }">
    </dxi-toolbar-item>
</dx-popup>