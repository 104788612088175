<div id="errorBox" class="error-box-container">
    <div class="error-box-title">{{title}}</div>
    <div class="error-box-content">
        <dx-scroll-view>
            <div class="error-box-item" *ngFor="let error of items" (click)="error.onClick('')">
                <div class="dx-icon-clear" *ngIf="error.type.toLowerCase() === 'fehler'"></div>
                <div class="dx-icon-info" *ngIf="error.type.toLowerCase() === 'information'"></div>
                <div class="error-box-item-category" *ngIf="error.category !== undefined && error.category!== null && error.category.length > 0">
                    {{error.category}}</div>
                {{error.message}}
            </div>
        </dx-scroll-view>
    </div>
</div>