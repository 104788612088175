import { Component, Input, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { RouteKeys } from '../../helper/route-keys.helper';
import { ApiService } from '../../services/api.service';
import { BilderEditComponent } from './bilder-edit/bilder-edit.component';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-bilder',
  templateUrl: './bilder.component.html',
  styleUrls: ['./bilder.component.scss']
})
export class BilderComponent {

  @ViewChild('bilderEditPopup', { static: false }) bilderEditPopup: BilderEditComponent;
  @ViewChild('bilderDataGrid', { static: false }) bilderDataGrid: DxDataGridComponent;


  @Input() type: string = null;
  @Input() readOnly: boolean = false;

  _entityId?: number = null;
  @Input() set entityId(id: number) {
    this._entityId = id;

    if (id !== null) {
      this.load();
    }
    else {
      this._entityId = null;
      this.dataSource = [];
    }
  }

  dataSource: any[] = [];
  selectedItem: any = null;

  constructor(private apiService: ApiService) {
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onReorder = this.onReorder.bind(this);
  }

  load() {
    this.apiService.post<any[]>(RouteKeys.Bilder.getAll, { type: this.type, entityId: this._entityId }).subscribe((response) => {
      this.dataSource = response;
    }, err => {
      throw err;
    });
  }

  onRowUpdated(e) {
    const bild = JSON.parse(JSON.stringify(e.data));
    bild.type = this.type;

    this.apiService.post<any[]>(RouteKeys.Bilder.update, bild).subscribe((response) => {
      this.load();
    }, err => {
      throw err;
    });
  }

  onSelectionChanged(e) {
    this.selectedItem = e.selectedRowsData[0];
  }

  onRowPrepared(e){  
    e.rowElement.style.height = '65px';  
}

  onToolbarPreparing(e) {
    var searchPanel = e.toolbarOptions.items.filter(item => item.name === 'searchPanel');
    if (searchPanel && searchPanel[0]) {
      searchPanel[0].location = 'before';
    }

    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      cssClass: 'datagridToolbarNewBtn',
      options: {
        disabled: this.readOnly,
        stylingMode: 'contained',
        type: 'default',
        icon: 'add',
        text: 'Hinzufügen',
        onClick: this.onClickNew.bind(this)
      }
    });
  }

  onContentReady(e) {
    this.bilderDataGrid.instance.clearSelection();

    e.component.columnOption('command:select', {
      visibleIndex: 1,
      fixedPosition: 'right',
      visibleWidth: 70
    });
  }

  onReorder(e) {
    var visibleRows = e.component.getVisibleRows();
    var toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
    var fromIndex = this.dataSource.indexOf(e.itemData);

    this.dataSource.splice(fromIndex, 1);
    this.dataSource.splice(toIndex, 0, e.itemData);

    for (let index = 0; index < this.dataSource.length; index++) {
      const element = this.dataSource[index];
      element.orderIndex = index;
    }

    this.apiService.post<any[]>(RouteKeys.Bilder.reOrder, { type: this.type, items: this.dataSource}).subscribe((response) => {
      this.load();
    }, err => {
      throw err;
    });
  }

  onClickNew(e) {
    this.bilderEditPopup.openNew(this.type, this._entityId);
  }

  onClickDelete(e) {
    let result = confirm('<i>Wollen Sie das Bild wirklich löschen?</i>', 'Bestätigung');
    result.then((dialogResult) => {
      if (dialogResult) {
        this.apiService.post(RouteKeys.Bilder.remove, { type: this.type, id: e.row.data.id }).subscribe((response) => {
          this.load();
        }, err => {
          throw err;
        });
      }
    });
  }

  onEditClose(e) {
    this.load();
  }

  onClickEdit(e) {
    e.component.editRow(e.row.rowIndex);
  }
}