import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PopupService {
  constructor() {}

  // Vorlage-Popup
  private _vorlagePopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_vorlagePopupAsObservable(): Observable<any> {
    return this._vorlagePopup.asObservable();
  }
  showVorlagePopup(id, closeCb: (e) => void = null) {
    this._vorlagePopup.next({
      open: true,
      key: id,
      closeCb: closeCb,
    });
  }

  // WiedervorlageGrid-Popup
  private _wiedervorlageGridPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_wiedervorlageGridPopupAsObservable(): Observable<any> {
    return this._wiedervorlageGridPopup.asObservable();
  }
  showWiedervorlageGridPopup(data: any, closeCb: (e) => void = null) {
    this._wiedervorlageGridPopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }

  // WiedervorlageEdit-Popup
  private _wiedervorlageEditPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_wiedervorlageEditPopupAsObservable(): Observable<any> {
    return this._wiedervorlageEditPopup.asObservable();
  }
  showWiedervorlageEditPopup(data: any, closeCb: (e) => void = null) {
    this._wiedervorlageEditPopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }

  // LoginCode-Popup
  private _loginCodeEditPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_loginCodePopupAsObservable(): Observable<any> {
    return this._loginCodeEditPopup.asObservable();
  }
  showLoginCodePopup(data: any, closeCb: (e) => void = null) {
    this._loginCodeEditPopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }
  // bgb-person-search-Popup
  private _bgbPersonSearchPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_bgbPersonSearchPopupAsObservable(): Observable<any> {
    return this._bgbPersonSearchPopup.asObservable();
  }
  showBgbPersonSearchPopup(data: any, closeCb: (e) => void = null) {
    this._bgbPersonSearchPopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }

  // bgb-person-edit-Popup
  private _bgbPersonEditPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_bgbPersonEditPopupAsObservable(): Observable<any> {
    return this._bgbPersonEditPopup.asObservable();
  }
  showBgbPersonEditPopup(data: any, closeCb: (e) => void = null) {
    this._bgbPersonEditPopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }

  // bgb-holidayCare-Popup
  private _bgbHolidayCarePopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_bgbHolidayCarePopupAsObservable(): Observable<any> {
    return this._bgbHolidayCarePopup.asObservable();
  }
  showBgbHolidayCarePopup(data: any, closeCb: (e) => void = null) {
    this._bgbHolidayCarePopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }

  //Stapelbearbeitung-Popup
  private _stapelBearbeitungPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_stapelBearbeitungPopupAsObservable(): Observable<any> {
    return this._stapelBearbeitungPopup.asObservable();
  }
  showStapelBearbeitungPopup(data: any, closeCb: (e) => void = null) {
    this._stapelBearbeitungPopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }

  // Dokumente bearbeiten-Popup
  private _dokumentEditPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_dokumentEditPopupAsObservable(): Observable<any> {
    return this._dokumentEditPopup.asObservable();
  }

  showDokumentEditPopup(data: any, closeCb: (e) => void = null) {
    this._dokumentEditPopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }

  private _ewoPersonSearchPopup: BehaviorSubject<any> = new BehaviorSubject(null);
  public get get_ewoPersonSearchPopupAsObservable(): Observable<any> {
    return this._ewoPersonSearchPopup.asObservable();
  }
  showEwoPersonSearchPopup(data: any, closeCb: (e) => void = null) {
    this._ewoPersonSearchPopup.next({
      open: true,
      data: data,
      closeCb: closeCb,
    });
  }
}
