export namespace RouteKeys {
  export namespace StartSite {
    export const get: string = 'StartSite/Get';
  }

  export namespace Auth {
    export const get_token: string = 'Auth/GetToken';
    export const psw_recover: string = 'ExternBenutzer/RecoverPassword';
    export const send_verify_code: string = 'Auth/SendVerifyCode';
    export const login_no_2FA: string = 'Auth/LoginNo2FA';
    export const get_is_2FA_on: string = 'Auth/GetIs2FAOn';
  }

  export namespace Bilder {
    export const getAll: string = 'Bilder/GetAll';
    export const add: string = 'Bilder/Add';
    export const update: string = 'Bilder/Update';
    export const reOrder: string = 'Bilder/ReOrder';
    export const remove: string = 'Bilder/Remove';
  }

  export namespace Dokumente {
    export const getAll: string = 'Dokumente/GetAll';
    export const save: string = 'Dokumente/Save';
    export const update: string = 'Dokumente/Update';
    export const download: string = 'Dokumente/Download';
    export const remove: string = 'Dokumente/Remove';
  }

  export namespace Poll {
    export const get: string = 'Poll/Get';
  }

  export namespace Config {
    export const get_config: string = 'Config/GetConfig';
    export const get_config_extern: string = 'Config/GetConfigNothAuth';
    export const get_version: string = 'Config/GetVersion';
    export const get_global_settings: string = 'GlobalSettings/GetValue';
  }

  export namespace EmailVerwaltung {
    export const get_email_vorlage: string = 'EmailVerwaltung/GetEmailVorlage';
    export const set_email_vorlage: string = 'EmailVerwaltung/SetEmailVorlage';
    export const get_email_vorlage_description: string = 'EmailVerwaltung/GetEmailVorlagenDescription';
  }

  export namespace Inquire {
    export const get_search: string = 'Inquire/Search';
    export const add_antrag: string = 'Inquire/AddAntrag';
    export const update_antrag: string = 'Inquire/UpdateAntrag';
    export const remove_antrag: string = 'Inquire/RemoveAntrag';
    export const get_antrag: string = 'Inquire/GetAntragById';
    export const get_kinder_dublette: string = 'Inquire/GetKinderDublette';
    export const check_user: string = 'Inquire/CheckUser';
    export const check_status: string = 'Inquire/CheckStatus';
    export const stapelbearbeitung: string = 'Inquire/Stapelbearbeitung';
    export const get_historie: string = 'Inquire/GetHistorie';
    export const get_all_historie: string = 'Inquire/GetAllHistorie';
  }

  export namespace Vorlage {
    export const get_search: string = 'Vorlagen/Search';
    export const create: string = 'Vorlagen/Create';
    export const save_as_pdf: string = 'Vorlagen/SaveAsPdf';
    export const create_without_download: string = 'Vorlagen/CreateWithoutDownload';
  }

  export namespace Wiedervorlage {
    export const get_search: string = 'Wiedervorlagen/Search';
    export const get_allByAntrag: string = 'Wiedervorlagen/GetAllByAntrag';
    export const get: string = 'Wiedervorlagen/Get';
    export const update: string = 'Wiedervorlagen/Update';
    export const finish: string = 'Wiedervorlagen/Finish';
  }
  export namespace Option {
    export namespace Benutzer {
      export const get_search: string = 'Benutzer/Search';
      export const get: string = 'Benutzer/Get';
      export const add: string = 'Benutzer/Add';
      export const update: string = 'Benutzer/Update';
      export const remove: string = 'Benutzer/Remove';
      export const get_profile: string = 'Benutzer/GetUserProfile';
      export const update_profile: string = 'Benutzer/UpdateUserProfile';
      export const set_password: string = 'Benutzer/SetUserPassword';
      export const get_search_buerger: string = 'Benutzer/SearchBuerger';

      export const get_buerger: string = 'Benutzer/GetBuerger';
      export const add_buerger: string = 'Benutzer/AddBuerger';
      export const update_buerger: string = 'Benutzer/UpdateBuerger';
      export const get_anzahl_antraege: string = 'Benutzer/GetAnzahlAntraege';
      export const remove_buerger: string = 'Benutzer/RemoveBuerger';
    }

    export namespace Vorlagenverwaltung {
      export const get_search: string = 'Vorlagenverwaltung/Search';
      export const download: string = 'Vorlagenverwaltung/Download';
      export const save: string = 'Vorlagenverwaltung/Save';
      export const remove: string = 'Vorlagenverwaltung/Remove';
      export const get_antrag_vorlage_description: string = 'Vorlagenverwaltung/GetAntragVorlagenDescription';
      export const get_antrag_vorlage_description_file: string = 'Vorlagenverwaltung/GetAntragVorlagenDescriptionFile';
      export const saveFromBase64: string = 'Vorlagenverwaltung/SaveFromBase64';
    }

    export namespace Kataloge {
      export const get_katalog: string = 'Katalog/GetKatalog';
      export const add_katalog: string = 'Katalog/AddKatalog';
      export const update_katalog: string = 'Katalog/UpdateKatalog';
      export const remove_katalog: string = 'Katalog/RemoveKatalog';
      export const sort_katalog: string = 'Katalog/SortKatalog';

      export const get_strassen_katalog: string = 'Katalog/GetStrassenKatalog';
      export const add_strassen_katalog: string = 'Katalog/AddStrassenKatalog';
      export const update_strassen_katalog: string = 'Katalog/UpdateStrassenKatalog';
      export const remove_strassen_katalog: string = 'Katalog/RemoveStrassenKatalog';
      export const remove_all_strassen_katalog: string = 'Katalog/RemoveAllStrassenKatalog';
      export const reorder_katalog: string = 'Katalog/ReorderKatalog';
    }

    export namespace Faq {
      export const get_question: string = 'Faq/Search';
      export const add: string = 'Faq/Add';
      export const update: string = 'Faq/Update';
      export const remove: string = 'Faq/Remove';
    }

    export namespace News {
      export const get_news: string = 'Neuigkeit/Search';
      export const add: string = 'Neuigkeit/Add';
      export const update: string = 'Neuigkeit/Update';
      export const remove: string = 'Neuigkeit/Remove';
    }

    export namespace Export {
      export const get_export: string = 'Export/Search';
      export const add_export: string = 'Export/Create';
      export const download_export: string = 'Export/DownloadExport';
      export const get_protocol: string = 'Export/GetProtocol';
      export const add_basedata_export: string = 'Export/CreateBaseData';
      export const download_export_basedata: string = 'Export/DownloadExportBasedata';
    }

    export namespace Settings {
      export namespace Allgemein {
        export const get: string = 'Settings/Get_Allgemein';
        export const save: string = 'Settings/Save_Allgemein';
      }

      export namespace Sicherheit {
        export const get: string = 'Settings/Get_Sicherheit';
        export const save: string = 'Settings/Save_Sicherheit';
      }

      export namespace Start {
        export const get: string = 'Settings/Get_Start';
        export const save: string = 'Settings/Save_Start';
      }

      export namespace Antrag {
        export const get: string = 'Settings/Get_Antrag';
        export const save: string = 'Settings/Save_Antrag';
      }

      export namespace Formularfelder {
        export const get: string = 'Settings/Get_Formularfelder';
        export const save: string = 'Settings/Save_Formularfelder';
      }

      export namespace Beitragsberechnung {
        export const get: string = 'Settings/Get_Calculator';
        export const save: string = 'Settings/Save_Calculator';
        export const get_tables: string = 'Settings/Get_FeeTables';
        export const get_table: string = 'Settings/Get_FeeTable';
        export const add_table: string = 'Settings/Add_FeeTable';
        export const save_table: string = 'Settings/Save_FeeTable';
        export const remove_table: string = 'Settings/Remove_FeeTable';
      }

      export namespace Bgb {
        export const get: string = 'Settings/Get_Bgb';
        export const save: string = 'Settings/Save_Bgb';
        export const holidaycare_get: string = 'Settings/Get_BgbHolidaycare';
        export const holidaycare_save: string = 'Settings/Save_BgbHolidaycare';
        export const mealallowance_get: string = 'Settings/Get_BgbMealAllowance';
        export const mealallowance_save: string = 'Settings/Save_BgbMealAllowance';
      }

      export namespace Export {
        export const get: string = 'Settings/Get_Export';
        export const save: string = 'Settings/Save_Export';
      }

      export namespace Ewo {
        export const get: string = 'Settings/Get_Ewo';
        export const save: string = 'Settings/Save_Ewo';
      }
    }
  }

  export namespace Einrichtung {
    export const get_search: string = 'Einrichtung/Search';
    export const get_einrichtungen_select: string = 'Einrichtung/GetEinrichtungenForSelect';
    export const get_einrichtungen_dashboard: string = 'Einrichtung/GetEinrichtungenForDashboard';
    export const get_einrichtung_by_id: string = 'Einrichtung/GetEinrichtungById';
    export const add_einrichtung: string = 'Einrichtung/AddEinrichtung';
    export const update_einrichtung: string = 'Einrichtung/UpdateEinrichtung';
    export const remove_einrichtung: string = 'Einrichtung/RemoveEinrichtung';
  }

  export namespace Kapazitaeten {
    export const get_all: string = 'Kapazitaet/Search';
  }

  export namespace Traeger {
    export const get_traeger: string = 'Traeger/GetAllTraeger';
    export const get_traeger_select: string = 'Traeger/GetTraegerForSelect';
    export const get_traeger_by_id: string = 'Traeger/GetTraegerById';
    export const add_traeger: string = 'Traeger/AddTraeger';
    export const update_traeger: string = 'Traeger/UpdateTraeger';
    export const remove_traeger: string = 'Traeger/RemoveTraeger';
  }

  export namespace Recherche {
    export const get_available_selects: string = 'Recherche/GetAvailableSelects';
    export const search: string = 'Recherche/Search';

    export const load_vorlagen: string = 'Recherche/LoadAllVorlagen';
    export const save_vorlage: string = 'Recherche/SaveVorlage';
    export const remove_vorlage: string = 'Recherche/RemoveVorlage';
  }

  export namespace Imprint {
    export const update_imprint: string = 'Imprint/Update';
    export const get_value: string = 'Imprint/GetValue';
  }

  export namespace Privacy {
    export const update_privacy: string = 'Privacy/Update';
    export const get_value: string = 'Privacy/GetValue';
  }

  export namespace Doctext {
    export const update_doctext: string = 'Doctext/Update';
    export const get_value: string = 'Doctext/GetValue';
  }

  export namespace Dev {
    export const logs_download: string = 'Develop/GetLogs';
    export const logs_delete: string = 'Develop/RemoveLogs';
  }

  export namespace Exception {
    export const log_error: string = 'Exception/LogError';
  }

  export namespace Bgb {
    export const haushalt_get: string = 'BeitragsberechnungHaushalt/Get';
    export const haushalt_save: string = 'BeitragsberechnungHaushalt/Save';
    export const haushalt_search: string = 'BeitragsberechnungHaushalt/Search';
    export const haushalt_delete: string = 'BeitragsberechnungHaushalt/Delete';
    export const ek_berechnungen_search: string = 'BeitragsberechnungHaushalt/SearchEkBerechnungen';
    export const ek_berechnung_get: string = 'BeitragsberechnungHaushalt/GetEkBerechnung';
    export const ek_berechnungen_get: string = 'BeitragsberechnungHaushalt/GetEkBerechnungenKurz';
    export const ek_berechnung_save: string = 'BeitragsberechnungHaushalt/SaveEkBerechnung';
    export const ek_berechnung_delete: string = 'BeitragsberechnungHaushalt/DeleteEkBerechnung';

    export const vertrag_get: string = 'BeitragsberechnungVertrag/Get';
    export const vertrag_save: string = 'BeitragsberechnungVertrag/Save';
    export const vertrag_search: string = 'BeitragsberechnungVertrag/Search';
    export const validate: string = 'BeitragsberechnungVertrag/Validate';
    export const vertrag_delete: string = 'BeitragsberechnungVertrag/Delete';
    export const vertrag_get_hh: string = 'BeitragsberechnungVertrag/GetHH';

    export const elternbeitrag_search: string = 'BeitragsberechnungElternbeitrag/Search';
    export const elternbeitrag_get: string = 'BeitragsberechnungElternbeitrag/Get';
    export const elternbeitrag_get_bb_kurz: string = 'BeitragsberechnungElternbeitrag/GetBearbeitungenKurz';

    export const elternbeitrag_save: string = 'BeitragsberechnungElternbeitrag/Save';
    export const elternbeitrag_delete: string = 'BeitragsberechnungElternbeitrag/Delete';
    export const elternbeitrag_timeline: string = 'BeitragsberechnungElternbeitrag/GetTimeLine';
    export const elternbeitrag_timeline2: string = 'BeitragsberechnungElternbeitrag/GetTimeLine2';

    export const person_search: string = 'BeitragsberechnungPerson/Search';
    export const person_search_kids: string = 'BeitragsberechnungPerson/SearchKids';
    export const person_get: string = 'BeitragsberechnungPerson/Get';
    export const person_save: string = 'BeitragsberechnungPerson/Save';

    export const essengeld_get: string = 'BeitragsberechnungEssengeld/Get';
    export const essengeld_save: string = 'BeitragsberechnungEssengeld/Save';

    export const ferienbetreuung_save: string = 'BeitragsberechnungFerienbetreuung/Save';
    export const ferienbetreuung_get: string = 'BeitragsberechnungFerienbetreuung/Get';

    export const ferien_get: string = 'Ferien/Search';
    export const ferien_save: string = 'Ferien/Save';
    export const ferien_delete: string = 'Ferien/Delete';

    // export const kinder_search: string = 'BeitragsberechnungKinder/Search';
  }
  
  export namespace Ewo {
    export const check_person_residence: string = 'Ewo/CheckPersonResidence';
    export const check_person_residence_vague: string = 'Ewo/CheckPersonResidenceVague';
  }

}
